.chat-mini {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; 
    overflow-y: hidden;
}

.icono-chat-mini{
    background-color: #1b334b;
    width: 50px;
    height: 50px;
    color: white;
    cursor: pointer;
    border-radius: 50%;
    padding-top: 5px;
}
.icono-chat-mini svg{
    font-size: 35px;
}

.chat-interface-mini{
    width: 350px;
    height: 400px;
    background-color: white;
    border: 1px solid gray;
    border-radius: 10px;
    overflow: hidden;
}
.search-user-mini{
    height: 30px;
    display: flex;
    padding: 10px;
    margin-bottom: 20px;
}
.search-user-mini input{
    height: 30px;
    font-size: 20px;
    border: none;
    padding: 10px;
}
.search-user-mini svg{
    color: gray;
    font-size: 30px;
    margin-top: 5px;
}