.qr-register-general{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 6%;
    margin-top: 5px;
}
.qr-register-general:hover{
    cursor: pointer;
}
.navegacioncompany{
    position: relative;
    margin: 0 auto;
}
.navegacioncompany button{
    min-width: 250px !important;
    height: 60px !important;
}

/* REGISTRO GENERAL */


.formulariopointofsale select{
    font-size: 17px;
}
.formulariopointofsale input{
    height: 60px !important;
}

.pestaña1r {
    padding-top: 5px;
    margin-left: 2%;
    width: 23%;
    height: 30px;
  }
  .socioinfo, .socioinfodir, .puntoventainfo, .puntoventainfodir{
    width: 100%;
    padding-bottom: 20px;
  }
  .pestaña2r {
    padding-top: 5px;
    height: 30px;
    width: 25%;
  }
  .pestaña3r {
    padding-top: 5px;
    height: 30px;
    width: 25%;
  }
  .pestaña4r{
    padding-top: 5px;
    margin-right: 2%;
    height: 30px;
    width: 23%;
  }
  
  .pestaña1r:hover{
    cursor:pointer;
  }
  .pestaña2r:hover{
    cursor:pointer;
  }
  .pestaña3r:hover{
    cursor: pointer;
  }
  .pestaña4r:hover{
    cursor: pointer;
  }
  
  .pestaña1r, .pestaña2r, .pestaña3r, .pestaña4r{
    display:inline-block
  }
  .title-register-genral{
    font-size: 40px;
  }
  
  .inputbusinessearch{
    width: 100%;
    height: 100%;
  }
  .socioinfo{
    margin-left: 0;
    margin: 0 auto;
  }
  .socioinfodir{
    margin-left: 0;
    margin: 0 auto;
  }
  
  .grids01{
    display: grid;
    width: 90%;
    grid-template-columns: 10% 4% 19% 20% 14% 14% 13%;
    grid-template-areas:
        "a b c c d e f";
    grid-gap: 10px;
    margin: 0 auto;
  }
  
  .item0{grid-area: a;}
  .item0ch{
    grid-area: b;
    transform: scale(1.5);
    padding-left: 10px;
  }
  .item0ch input[type=checkbox]{
    width: 20px !important;
    height: 20px !important;
  }
  .itemch input[type=checkbox]{
    width: 30px !important;
    height: 30px !important;
    margin-left: 20px;
  }

  .items01{grid-area: c;}
  .items02{grid-area: d;}
  .items03{grid-area: e;}
  .items04{grid-area: f;}
  .grids02{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 10% 4% 19% 20% 14% 14% 13%;
    grid-template-areas:
        "a a a b b c c";
    grid-gap: 10px;
  }
  .items05{grid-area: a;}
  .items06{grid-area: b;}
  .items07{grid-area: c;}
  .grids03{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 10% 4% 19% 20% 14% 14% 13%;
    grid-template-areas:
        "a a b b c d d"
        "e e e e f f f";
    grid-gap: 10px;
  }
  .items08{grid-area: a;}
  .items09{grid-area: b;}
  .items10{grid-area: c;}
  .items11{grid-area: d;}
  
  .items12{grid-area: e;}
  .items13{grid-area: f;}
  
  .grids04{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 10% 4% 19% 20% 14% 14% 13%;
    grid-template-areas:
        "a a a b b c d";
    grid-gap: 10px;
  }
  .items14{grid-area: a;}
  .items15{grid-area: b;}
  .items16{grid-area: c;}
  .items17{grid-area: d;}
  
  
  .grids50{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 10% 4% 19% 20% 14% 14% 13%;
    grid-template-areas:
        "a a a a b b b";
    grid-gap: 10px;
  }
  .grids51{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 10% 4% 19% 14% 20% 14% 13%;
    grid-template-areas:
     "a a a a b b b";
    grid-gap: 10px;
  }
  .items51{grid-area: a;}
  .items52{grid-area: b;}
  .items53{grid-area: a;}
  .items54{grid-area: b;}
  
  
  
  .grids05{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 10% 10% 15% 15% 14% 15%;
    grid-template-areas:
        "a a a b c d d";
    grid-gap: 10px;
  }
  .items18{grid-area: a;}
  .items19{grid-area: b;}
  .items20{grid-area: c;}
  .items21{grid-area: d;}
  .grids06{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 10% 10% 15% 15% 14% 15%;
    grid-template-areas:
        "a a a b b c d";
    grid-gap: 10px;
  }
  .items22{grid-area: a;}
  .items23{grid-area: b;}
  .items24{grid-area: c;}
  .items25{grid-area: d;}
  .grids07{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 10% 10% 15% 15% 14% 15%;
    grid-template-areas:
        "a b b b b c d";
    grid-gap: 10px;
  }
  .items26{grid-area: a;}
  .items27{grid-area: b;}
  .items28{grid-area: c;}
  .items29{grid-area: d;}
  
  /* pestaña de puntos de venta de registro general */
  
  .gridpg01{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 10% 4% 19% 20% 14% 14% 13%;
    grid-template-areas:
        "a b c c c d d";
    grid-gap: 10px;
  }
  .gridpg02{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 10% 4% 19% 20% 14% 14% 13%;
    grid-template-areas:
        "d d d d e e e";
    grid-gap: 10px;
  }
  .gridpg03{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 10% 4% 19% 20% 14% 14% 13%;
    grid-template-areas:
        "a a a b c d d";
    grid-gap: 10px;
  } 
  .gridpg04{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 10% 4% 19% 20% 14% 14% 13%;
    grid-template-areas:
        " e e e f f g g";
    grid-gap: 10px;
  } 
  .gridpg05{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 10% 4% 19% 14% 20% 14% 13%;
    grid-template-areas:
        "a a b b c d d";
    grid-gap: 10px;
  } 
  
  /* campos de direccion de punto de venta en registro general  */
  .gridpg06{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 14% 14% 7% 14% 18% 14% 13%;
    grid-template-areas:
        "a b c d d d d";
    grid-gap: 10px;
  } 
  .gridpg07{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 14% 14% 7% 14% 18% 14% 13%;
    grid-template-areas:
        "a b c c c d d";
    grid-gap: 10px;
  } 
  .gridpg08{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 14% 14% 7% 14% 18% 14% 13%;
    grid-template-areas:
        "a a a b b c d";
    grid-gap: 10px;
  } 
  
  .gridpg09{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 14% 14% 7% 14% 18% 14% 13%;
    grid-template-areas:
        "a a a a a b b";
    grid-gap: 10px;
  } 
  
  
  
  
  
  @media only screen and (max-width: 720px) {
    
    .socioinfo, .socioinfodir, .puntoventainfo, .puntoventainfodir{
      overflow: scroll;
    }
  
    .grids01{
      grid-template-areas:
          "a a b c c c c"
          "d d d e e f f"
    }
   .grids02{
    grid-template-areas:
        "a a a a a a a"
        "b b b b c c c";
   }
   .grids03{
    grid-template-areas:
        "a a a a b b b"
        "c c c c d d d"
        "e e e e e e e"
        "f f f f f f f";
    }
    .grids04{
      grid-template-areas:
          "a a a a b b b"
          "c c c d d d d";
    }
    .grids05{
     grid-template-areas:
          "a a a a b b b"
          "c c c c d d d";
    }
  
    .grids06{
      grid-template-areas:
           "a a a a b b b"
           "c c c c d d d";
     }
     .grids07{
      grid-template-areas:
           "a a a a b b b"
           "c c c c d d d";
     }
  
    .gridpg01{
      grid-template-areas:
        "a a a b d d d"
        "c c c c c c c"
    }
    .gridpg02{
      grid-template-areas:
        "d d d d d d d"
        "e e e e e e e";
    }
    .gridpg03{
      grid-template-areas:
        "a a a a b b b"
        "c c c d d d d";
    } 
    .gridpg04{
      grid-template-areas:
        "e e e e e e e"
        "f f f f g g g";
    } 
    .gridpg05{
      grid-template-areas:
        "a a a a b b b"
        "c c c c d d d";
    } 
    .gridpg06{
      grid-template-columns: 24% 24% 7% 14% 8% 4% 13%;
      grid-template-areas:
       "a b c d d d d";
    } 
    .gridpg07{
      grid-template-areas:
        "a a a b b b b"
        "c c c d d d d";
    } 
    .gridpg08{
      grid-template-areas:
       "a a a a b b b"
       "c c c c d d d";
    } 
    .gridpg09{
      grid-template-areas:
          "a a a a b b b";
    } 
  
  }
  
  @media only screen and (max-width: 480px) {
    .grids01{
      grid-template-areas:
          "a a a a a b b"
          "c c c c c c c"
          "d d d d d d d"
          "e e e e e e e"
          "f f f f f f f"
    }
    .grids02{
      grid-template-areas:
          "a a a a a a a"
          "b b b b b b b"
          "c c c c c c c";
     }
     .grids03{
      grid-template-areas:
          "a a a a a a a"
          "b b b b b b b"
          "c c c c c c c"
          "d d d d d d d"
          "e e e e e e e"
          "f f f f f f f";
      }
      .grids04{
        grid-template-areas:
            "a a a a a a a"
            "b b b b b b b"
            "c c c c c c c"
            "d d d d d d d";
      }
      .grids05{
        grid-template-areas:
             "a a a a a a a"
             "b b b b b b b"
             "c c c c c c c"
             "d d d d d d d";
       }
       .grids50{
        grid-template-areas:
            "a a a a a a a"
            "b b b b b b b"
      }
      
      .grids51{
        grid-template-areas:
            "a a a a a a a"
            "b b b b b b b"
      }
       .grids06{
         grid-template-areas:
              "a a a a a a a"
              "b b b b b b b"
              "c c c c c c c"
              "d d d d d d d";
        }
        .grids07{
         grid-template-areas:
              "a a a a a a a"
              "b b b b b b b"
              "c c c c c c c"
              "d d d d d d d";
        }
        
    .gridpg01{
      grid-template-areas:
        "a a a a a a b"
        "c c c c c c c"
        "d d d d d d d"
        "e e e e e e e";
    }
    .gridpg02{
      grid-template-areas:
        "d d d d d d d"
        "e e e e e e e";
    }
    .gridpg03{
      grid-template-areas:
        "a a a a a a a"
        "b b b b b b b"
        "c c c c c c c"
        "d d d d d d d";
    } 
    .gridpg04{
      grid-template-areas:
        "e e e e e e e"
        "f f f f f f f"
        "g g g g g g g";
    } 
  .gridpg05{
    grid-template-areas:
        "a a a a a a a"
        "b b b b b b b"
        "c c c c c c c"
        "d d d d d d d";
    } 
    .gridpg06{
      grid-template-columns: 14% 14% 6% 10% 20% 15% 13%;
      grid-template-areas:
       "a a a a b b b b"
       "c d d d d d d d";
    } 
    .gridpg07{
      grid-template-areas:
        "a a a a a a a"
        "b b b b b b b"
        "c c c c c c c"
        "d d d d d d d";
    } 
    .gridpg08{
      grid-template-areas:
       "a a a a a a a"
       "b b b b b b b"
       "c c c c c c c"
       "d d d d d d d";
    } 
    .gridpg09{
      grid-template-areas:
        "a a a a a a a"
        "b b b b b b b"
        "c c c c c c c";
    } 
  }
