.patch-notes-container{
    width: 100%;
    margin: 0 0;
    position: relative;
}
.patch-notes-content .navbar-patch-notes{
    width: 100% !important;
    min-width: 102% !important;
    margin: 0 auto;
    height: 120px;
    margin-top: -25px;
    margin-left: -5px;
    padding: 20px;
    align-items: center;
    margin-bottom: 50px;
    background: rgb(26,55,77);
    background: linear-gradient(180deg, rgba(26,55,77,1) 10%, rgb(12, 31, 46) 80%);
    position: relative;
}
.navbar-patch-notes h2{
    font-size: 50px;
    text-align: left;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: white;
    padding-top: 0px;
    margin-top: 0px;
}

.acciones-button-patch{
    margin: 0 auto;
    margin-top: -20px;
    width: 90%;
    display: flex;
    justify-content: flex-end;
}

.acciones-button-patch p{
    color: black;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    width: auto;
    margin: 0;

}


.body-patch-notes{
    width: 100%;
    margin: 0 auto;
}
.body-carts-content{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-areas: "a b c";
    width: 90%;
    margin: 0 auto;
    grid-gap: 4%;
}
.body-carts-version{
    width: 100%;
    display: grid;
    grid-template-rows: 60% 40%;
    grid-template-areas: "a""b";
    height: 300px;
    
    border-radius: 5px;
    overflow: hidden;
}
.body-carts-version .carts-version-image{
    width: 100%;
    height: 100%;
    overflow: hidden;
    grid-area: a;
    cursor: pointer;
}
.body-carts-version .carts-version-image img{
    width: 100%;
}
.body-carts-version .carts-version-description{
    width: 100%;
    height: 100%;
    overflow: hidden;
    grid-area: b;
    padding-top: 10px;
}
.carts-version-description .date-v{
    font-size: 16px;
    color: black;
    padding-bottom: 0px;
    margin-bottom: 5px;
    text-align: left;
}
.carts-version-description .date-v span{
    color: #e3bc6c;
    font-size: 16px;
    font-weight: bold;
    padding-right: 10px;
}
.carts-version-description .title{
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin-bottom: 5px;
    text-align: left;
}
.carts-version-description .description{
    font-size: 18px;
    color: black;
    text-align: justify;
    margin: 0 auto;
}
.carts-version-image img {
    transition: transform 0.3s ease;
}

.carts-version-description{
    position: relative;
}

.carts-version-description svg{
    color: red;
    font-size: 40px;
    text-align: right;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-top: 10px;
}


.body-carts-version:hover .carts-version-image img {
    transform: scale(1.2);
}

@media screen and (max-width: 1000px) {
    .body-carts-content{
        grid-template-columns: 45% 45%;
        grid-template-areas: "a b";
        width: 90%;
        margin: 0 auto;
        grid-gap: 4%;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 480px) {
    .body-carts-content{
        grid-template-columns: 90%;
        grid-template-areas: "a";
        width: 90%;
        margin: 0 auto;
        grid-gap: 0px;
    }
}
.acciones-button-patch{
    height: 70px;
    display: flex;
    text-align: center;
    width: 70%;
}
.search_note{
    font-size: 20px;
    height: 50px;
    border: 2px solid rgba(3, 27, 66, 0.741);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.button-search-notes{
    height: 50px;
    background-color: #132b3e;
    color: white;
    width: 60px;
    font-size: 30px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.button-tools-notes{
    height: 50px;
    background-color: #321455;
    color: white;
    width: 60px;
    font-size: 30px;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.button-create-notes{
    height: 50px;
    background-color: #f39c12;
    color: white;
    font-size: 30px;
    width: 60px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 10px;
}