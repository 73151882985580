.grid-real-sale-inputs{
    display: grid;
    grid-template-columns: 15% 10% 5% 5% 15% 5% 25%;
    grid-template-areas: "a b c d e f";
    grid-gap: 10px;
    width: 100%;
}

.button-close-filter{
    background-color: orange !important;
}
.button-close-filter:hover{
    background-color: rgb(218, 153, 34) !important; 
}

.button-show_points_not_visited{
    background-color: #630971 !important;
}
.sales_in_realtime_mapbox{
    width: 95%;
    margin: 0 auto;
    text-align: center;
    position: relative;
}
.search-filters-realtime-sales{
    width: 95%;
    margin: 0 auto;
}
.symbologic-filter{
    width: 220px;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    margin-left: 20px;
    margin-top: 20px;
    padding: 5px;
}
.symbology-items{
    width: 95%;
    margin: 0 auto;
}
.symbology-grid-items-realtime{
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: "a b";
    grid-gap: 5px;
    margin-bottom: 5px;
}
.symbology-grid-items-realtime img{
    width: 40px;
    text-align: center;
}
.symbology-grid-items-realtime p{
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    padding-top: 5px;
}   
.symbology-grid-items-realtime p span{
    font-weight: 100;
}

.marker-realtime-sales .marker-label {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 15px;
    font-weight: bold;
    color: black;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3); */
  }
  .total-real-sales-timereal{
    font-size: 18px;
    padding-top: 20px;
    text-align: left;
    padding-left: 10px;
  }