/* SliderComponent.css */
.slider-container {
    position: relative;
    width: 650px;
    overflow: hidden;
    margin: 0 auto;

  }
  
  .slider-image {
    width: 90%;
    height: auto;
    padding-bottom: 6%;
  }
  
  .slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: rgb(0, 0, 0);
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .left {
    left: 0px;
  }
  
  .right {
    right: 0px;
  }
  
  .slider-button:hover {
    color: #3498db;
  }
  
  .grid-graph{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-areas: "a b c";
  }
  .grp1{
    font-size: 20px;
    grid-area: a;
  }
  .grp2{
    font-size: 20px;
    grid-area: b;
  }
  .grp3{
    font-size: 20px;
    grid-area: c;
  }