.breadcrumbs-general {
  text-align: left;
  margin: 0 auto;
  width: 100%;
  font-size: 18px;
  position: relative;
  z-index: 150;
  margin-left: 25px;
}

.breadcrumbs-general a {
  text-decoration: none;
  color: black !important;
  padding-left: 15px;
  padding-right: 15px;
}

.breadcrumbs-general a:hover {
  text-decoration: underline;
  background-color: rgb(26, 55, 77);
  color: white !important;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  cursor: pointer;
}

.breadcrumbs-general span {
  color: gray;
  padding-left: 10px;
  padding-right: 10px;
}

.alert-new-version .css-1pxa9xg-MuiAlert-message {
  color: white !important;
}
.alert-new-version .css-1vooibu-MuiSvgIcon-root {
  color: white !important;
}
.alert-new-version a {
  color: white !important;
}
.breadcrumbs-general .color-url-new {
  color: white !important;
}
.css-1xsto0d {
  color: white !important;
}

.alert-new-version .css-1xsto0d {
  color: white !important;
}

.css-1l54tgj {
  color: white !important;
}

.css-q28n79 {
  color: white !important;
}

.card-container {
  /* position: fixed; */
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: absolute;
  z-index: 10;
  right: 0px;
  top: -10px;
}

.card-container-2 {
  display: flex;
  justify-content: space-around;
  position: absolute;
  flex-wrap: wrap;
  z-index: 10;
  margin-top: -36px;
  right: -1px;
}
.card {
  max-width: 345px;
  margin: 8px;
}

.MuiCardContent-root {
  padding: 5px !important;
  min-width: 200px !important;
  max-width: 300px !important;
}
.MuiCardContent-root p {
  padding-top: 8px;
}

.card1ddd {
  background: red !important;
}

.card-box {
  position: relative;
  color: #fff;
  width: 110px;
  cursor: pointer;
  height: 50px;
  /* padding: 20px 10px 40px; */
  /* margin: 20px 0px; */
  border-radius: 3px;
}
.card-box:hover {
  text-decoration: none;
  color: #f1f1f1;
}
.card-box:hover .icon i {
  font-size: 60px;
  transition: 1s;
  -webkit-transition: 1s;
}
.card-box .inner {
  /* padding: 5px 10px 0 10px; */
}
.card-box h3 {
  color: white;
  font-size: 15px;
  font-weight: bold;
  margin: 0 ;
  white-space: nowrap;
  padding: 0;
  text-align: left;
}
.card-box p {
  font-size: 15px;
  color: white;
  font-weight: bold;
}
.card-box .icon {
  position: absolute;
  top: auto;
  bottom: 1px;
  right: 5px;
  z-index: 0;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.15);
}
.card-box .card-box-footer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  text-align: center;
  padding: 1px 0;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  text-decoration: none;
}
.card-box:hover .card-box-footer {
  background: rgba(0, 0, 0, 0.3);
}
.bg-blue {
  background-color: #00c0ef !important;
}
.bg-green {
  background-color: #00a65a !important;
}
.bg-orange {
  background-color: #f39c12 !important;
}
.bg-red {
  background-color: #d9534f !important;
}
.icon-vi {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.card-box:hover .icon-vi {
  font-size: 50px;
  transition: 0.8s;
  -webkit-transition: 0.8s;
}

.custom-modal-report {
  width: 30% !important;
  min-width: 30% !important;
}


@media only screen and (max-width: 1200px) {
  .breadcrumbs-grid-area{
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: "a""b";
    width: 100%;
    margin: 0 0;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .breadcrumbs-general{
    grid-area: a;
    position: relative;
  }
  .breadcrumb-access-level{
    width: 100%;
    display: flex;
    font-size: 18px;
    padding-bottom: 15px;
  }
  .card-new-cont{
    grid-area: b;
    position: relative;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-areas: "a b c";
    grid-gap: 10px;
    width: 95%;
    margin: 0 0;
    overflow: hidden;
  }
  .card-new-cont .card-box{
    width: 100%;
  }
}

@media only screen and (max-width: 720px) {
  .breadcrumbs-grid-area{
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: "a""b";
    width: 100%;
    margin: 0 0;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .breadcrumbs-general{
    grid-area: a;
    position: relative;
  }
  .breadcrumb-access-level{
    width: 100%;
    display: flex;
    font-size: 18px;
    padding-bottom: 15px;
  }
  .card-new-cont{
    grid-area: b;
    position: relative;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-areas: "a b c";
    grid-gap: 10px;
    width: 95%;
    margin: 0 0;
    overflow: hidden;
  }
  .card-new-cont .card-box{
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {

  .content-minwid-menu{
    height: 100%;
    width: 300px;
    background-color: white;
    padding: 10px;
    z-index: 150 !important;
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid rgb(145, 145, 145);
  }
  .controlInvoice{
    display: block !important;
    min-width: 100%;
  }
  .controlInvoice{
    line-height: 60px;
  }
  .breadcrumbs-grid-area{
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: "a""b";
    width: 100%;
    margin: 0 0;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .breadcrumbs-general{
    grid-area: a;
    position: relative;
  }
  .breadcrumb-access-level{
    width: 100%;
    display: flex;
    font-size: 12px;
    padding-bottom: 15px;
  }
  .card-new-cont{
    grid-area: b;
    position: relative;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-areas: "a b c";
    grid-gap: 10px;
    width: 95%;
    margin: 0 0;
    overflow: hidden;
  }
  .card-new-cont .card-box{
    width: 100%;
  }
}