.gridproductonewdash{
    display: grid;
    grid-template-columns: 15% 15% 15% 20%;
    grid-template-areas: "a b c d";
    grid-gap: 10px;
    width: 50%;
    position: relative;
    z-index: 200 !important;
}
.gridproductonewdash .expen-1{
    grid-area: a;
}
.gridproductonewdash .expen-2{
    grid-area: b;
}
.gridproductonewdash .expen-3{
    grid-area: c;
}
.gridproductonewdash .expen-4{
    grid-area: d;
}