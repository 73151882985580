.point-info{
    height: 150px;
    max-height: 150px;
    overflow: auto;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 50px;
}

.point-info h2{
    font-size: 20px;
    font-weight: bold;
}

.point-info p{
    text-align: justify;
}



.container-confirm{
    text-align: center;
    width: 100%;
}
.confirmar{
    font-size: 18px;
    color: black;
}
.confirmar span{
    font-weight: bold;
}
.input-confir{
    width: 80%;
    margin: 0 auto;
    font-size: 18px;
}
.input-confir input{
    width: 100%;
    font-size: 18px;
    padding: 10px;
}
.content-progrs{
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-areas: "a b";
}
.content-progrs div{
    font-size: 18px;
    text-align: center;
    padding-top: 0px;
    width: 100%;
    border-radius: 5px;
    height: 80%;
    
}
.progress-points{
    text-align: justify;
    /* background-color: rgb(223, 223, 223);    */
    /* padding: 10px;  */
}
.progress-bar-ind{
    background-color: rgb(216, 216, 216);
    color: black;
}
.progress-bar-pross{
   background-color: rgb(99, 196, 238);
   color: black;
}
.progress-bar-error{
    background-color: rgb(195, 66, 66);
    color: white;
}
.progress-bar-finish{
    background-color: rgb(96, 216, 126);
    color: white;
}

.progres-error{
    background-color: rgb(255, 14, 14) !important; 
}
.container-upload-csv{
    padding-bottom: 10px;
}
.progress-points{
    max-height: 200px;
    overflow-y: auto;
}
.drop-area{
    margin-bottom: 50px;
}
.err-msj{
    color: red;
    width: 80%;
    margin: 0 auto;
    margin-top: 0px;
    padding-top: 0px;
}