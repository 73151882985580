.gastosnodedutable{
    display: grid;
    width: 60%;
    grid-template-columns: 15% 15% 20%;
    grid-template-areas: "a b c";
    grid-gap: 10px;
    position: relative;
    z-index: 200;
}
.gastosnodedutable .expen-1{
    grid-area: a;
}
.gastosnodedutable .expen-2{
    grid-area: b;
}
.gastosnodedutable .expen-3{
    grid-area: c;
}
.grid-tools-report-expenses{
    width: 60% !important;
}
.tools-report-expenses{
    position: relative;
    width: 60% !important;
}
.tools-report-expenses .expen-3 button{
    height: 30px !important;
}