
.containerselectfrezz{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 20% 5% 15% 15% 60%;
    grid-template-areas:"a x b c d";
    grid-gap: 10px;
    width: 100%;
    height: 22px;
    position: relative;
    margin-bottom: 15px;
    z-index: 100 !important;  
}
.TableCongeladores{
    margin-top: -18px !important;
}
.tablecongprinc{
    margin-top: -60px;
}
.selectfres_upload{
    grid-area: x;
}

.selectfres{
    width: 100%;
    grid-area: a;
    margin-top: 20px; 
}
.selectfres select{
    font-size: 15px;
    padding: 0px;
    padding-left: 10px;
    height: 45px;
}
.selectfres2{
    grid-area: b;
    margin-top: 25px;
}
.selectfres2 .button-cancel{
    background-color: red;
    border-radius: 5px;
    margin-top: 5px;
    height: 48px;
}
.selectfres2 button{
    width: 80%;
    height: 48px;
    font-size: 17px;
}
.selectfres3{
    grid-area: c;
    margin-top: 25px;
}
.selectfres3 button{
    width: 100%;
    font-size: 17px;
    height: 48px;
}

.button-confirm{
    background-color: #11AC09;
    margin-top: 5px;
    border-radius: 5px;
}
.TableCongeladores .containerCutBox{
    margin-top: 36px;
}
.TableCongeladores .titletable h1{
    font-size: 40px;
}

.TableCongeladores .sticky-table-table{
   width: 100%;
   font-size: 15px;
}
.TableCongeladores{
    margin: 0 auto;
    width: 95% !important;
}
.TableCongeladores .titletable{
    margin-top: -30px;
}

.TableCongeladores th:nth-child(1) img{
    width: 35px !important;
    height: 35px !important;
}
.TableCongeladores td:nth-child(1){
    min-width: 70px !important;
}

.TableCongeladores td:nth-child(2){
    white-space: normal;
}
.TableCongeladores td:nth-child(2) img:hover{
    cursor: pointer;
}
.TableCongeladores td:nth-child(2){
    min-width: 120px;
}

.TableCongeladores th:nth-child(3){
    white-space: normal;
    max-width: 120px;
}

.TableCongeladores td:nth-child(3),
.TableCongeladores td:nth-child(4),
.TableCongeladores td:nth-child(5),
.TableCongeladores td:nth-child(6),
.TableCongeladores td:nth-child(7),
.TableCongeladores td:nth-child(8){
    text-align: center;
}
.TableCongeladores td:nth-child(9){
    max-width: 150px;
    white-space: normal;
    text-align: justify;
}
.TableCongeladores td:nth-child(10){
    white-space: normal;
}

.TableCongeladores td:nth-child(10),
.TableCongeladores td:nth-child(11){
    text-align: center;
}
.TableCongeladores td:nth-child(12){
    text-align: left;
}
.TableCongeladores td:nth-child(13){
    text-align: center;
}
.TableCongeladores td:nth-child(14){
    text-align: left;
}

.TableCongeladores td:nth-child(15){
    text-align: center;
}

.form-frezz{
 font-size: 20px;
}
.form-frezz label{
    font-size: 22px;
    color: black;
}
.form-frezz input{
    height: 60px;
    font-size: 18px;
}
.gridfrez1{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:"a a b b c c";
    grid-gap: 10px;
}

.frez1{grid-area:a;}
.frez2{grid-area:b;}
.frez3{grid-area:c;}

.gridfrez2{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:"a a b b c c";
    grid-gap: 10px;
}

.frez4{
    grid-area:a;
    position: relative;
    margin-left: 10px;
    margin-top: 12px;
} 
.frez4 label{
    position: absolute;
    top: 0;
    margin-top: -10px;
    margin-left: 10px;
    background-color: white;
    font-size: 17px;
}
.frez5{grid-area:b;}
.frez6{grid-area:c;}

.gridfrez3{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 13% 17% 17% 15% 13%;
    grid-template-areas:"a a b b c c";
    grid-gap: 10px;
}
.frez7{grid-area: a;}
.frez8{grid-area: b;}
.frez9{grid-area: c;}
.frezn0{grid-area: n;}


.gridfrez4{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:"n n a a a a";
    grid-gap: 10px;
}

.frez10{grid-area: a;}
.frez11{grid-area: b;}
.frez12{grid-area: c;}
.frez13{grid-area: d;}
.frez111{grid-area: b; text-align: right;}
.frez111 button{
    width: 80%;
    margin-top: 12px !important;
    margin: 0 auto;
    border-radius: 10px;
    background-color: purple;
}
.frez111 button svg{
    font-size: 40px;
}

.iconos-frez{
    width: 25px;
    height: 25px;
    margin: 2px;
}

/* movimiento de almance */
.waremovfrezz{
    margin-top: -20px;
}
.grid-mov-almacen{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 100%;
    grid-template-areas:
        "a"
        "b"
        "c";
    grid-gap: 10px;
}   
.mov-almac-1{grid-area: a;}
.mov-almac-2{grid-area: b; margin-right: 10px;}
.mov-almac-3{grid-area: c; margin-right: 10px;}
.puntoventafrezz{
    margin-left: 12px;
}
.puntoventafrezz input {
    height: 50px !important;
}
.mov-almac-3 input{
    height: 60px;
}


.subtablehistoryfrez .sticky-table-table{
    width: 100%;
    font-size: 15px;
}
.subtablehistoryfrez {
    width: 60% !important;
    margin: 0 0;
}
.subtablehistoryfrez{
    text-align: center;
}

.subtablehistoryfrez th:nth-child(1){
    text-align: center;
    white-space: normal;
    max-width: 80px !important;
}
.subtablehistoryfrez td:nth-child(2){
    max-width: 100px;
    white-space: normal;
    text-align: center;
}
.subtablehistoryfrez td:nth-child(3){
    text-align: left;
}
.subtablehistoryfrez td:nth-child(4){
    text-align: left;
    white-space: normal;
    max-width: 100px;
}
.subtablehistoryfrez td:nth-child(5){
    text-align: left;
    white-space: normal;
    max-width: 100px;
}
.subtablehistoryfrez td:nth-child(6){
    text-align: left;
    white-space: normal;
    max-width: 100px;
}


/* detalles del congelador */
.grid-details-freez{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 45% 45%;
    grid-template-areas:
        "a b""c d""e f""g h""i j";
}
.bitac-frez{
    padding-top: 20px;
    font-size: 25px;
    color: black;
    text-align: left;
    margin: 0 auto;
    width: 90%;
    font-weight: bold;
}
.textareapuntosventa{
    font-size: 20px;
}

.grid-details-freez p{
    text-align: left;
    height: 17px;
}
.grid-details-freez .t{
    font-size: 20px;
    font-weight: bold;
    color: black;
}
.grid-details-freez .d{
    font-size: 20px;
    color: black;
}

.det-frez-1{grid-area: a;}
.det-frez-1a{grid-area: b;}
.det-frez-2{grid-area: c;}
.det-frez-2a{grid-area: d;}
.det-frez-3{grid-area: e;}
.det-frez-3a{grid-area: f;}
.det-frez-4{grid-area: g;}
.det-frez-4a{grid-area: h;}
.det-frez-5{grid-area: i;}
.det-frez-5a{grid-area: j;}



.grid-mov-almacen label{
    font-size: 20px;
}
/* modal para confirmar descapitalizacion de congeladors */
.descripcion-desc-confirm{
    width: 85%;
    margin: 0 auto;
}
.descripcion-desc-confirm p{
    font-size: 20px;
    text-align: justify;
    height: 10px;
    margin-left: 15px;
}
.container-confirm-desc .nota-desc{
    width: 85%;
    margin: 0 auto;
}
.nota-desc input{
    height: 50px;
    font-size: 20px;
}
.nota-desc label{
    font-size: 20px;
}
.b-p{
    font-size: 20px;
    font-weight:bold;
    color: black;
}

.checktableconge{
    background-color: white;
    color: white;
    height: 30px;
    width: 30px;
    padding: 0;
    margin: 0 auto;
}   
.checktableconge span{
    width: 30px;
    height: 30px;
}


.frez1{grid-area: a;}
.frez2{grid-area: b;}
.frez3{grid-area: c;}


@media only screen and (max-width: 720px) {
    .gridfrez1{
        display: grid;
        width: 90%;
        grid-template-columns: 46% 46%;
        grid-template-areas: 
        "a b"
        "c c";
        grid-gap: 10px;
        overflow-x: hidden;
    }
    .gridfrez2{
        display: grid;
        width: 90%;
        grid-template-columns: 46% 46%;
        grid-template-areas: 
        "a b"
        "c c";
        grid-gap: 10px;
        overflow-x: hidden;
    }
    .gridfrez3{
        display: grid;
        width: 90%;
        grid-template-columns: 46% 46%;
        grid-template-areas: 
        "a b"
        "c c";
        grid-gap: 10px;
        overflow-x: hidden;
    }
    .gridfrez4{
        grid-template-columns: 94.5%;
        width: 90%;
        margin: 0 auto;
        grid-gap: 0px;
        grid-template-areas: "n""a";
        overflow: hidden;
    }


}

@media only screen and (max-width: 480px) {
    .form-frezz{
        text-align: center;
        width: 98%;
        padding: 0px;
        margin: 0 auto;
    }

    .gridfrez1{
        display: grid;
        width: 100%;
        grid-template-columns: 92%;
        grid-template-areas: 
        "a""b""c";
        grid-gap: 0px;
    }
    .gridfrez2{
        display: grid;
        width: 100%;
        grid-template-columns: 92%;
        grid-template-areas: 
        "a""b""c";
        grid-gap: 0px;
    }
    .frez4 input{
        width: 104% !important;
    }
    .gridfrez3{
        display: grid;
        width: 100%;
        grid-template-columns: 92%;
        grid-template-areas: 
        "a""b""c";
        grid-gap: 0px;
    }
    .gridfrez4{
        grid-template-columns: 92%;
        width: 100%;
        margin: 0 auto;
        grid-gap: 0px;
        grid-template-areas: "n""a";
    }
    .containerselectfrezz{
        position: relative;
        width: 90% !important;
        margin: 0 0;
        padding-top: 0px;
        margin-top: 0px;
        display: grid;
        grid-template-columns: 25% 15% 25% 30%;
        grid-template-areas: "a x b c";
    }
    .selectfres{
        grid-area: a;
        font-size: 15px;
    }
    .selectfres2 button, .selectfres3 button{
        border-radius: 5px;
        margin-top: 5px;
        overflow: hidden;
        font-size: 12px;
        text-align: center;
        padding: 5px;
        white-space: nowrap; 
        text-overflow: ellipsis;
    }


}


/* MODULO DE CONSULTA DE MOVIMIENTOS DE ALMACENES DE CONGELADORES */

.TableCongeladores-warehouse .containerCutBox{
    margin-top: 36px;
}
.TableCongeladores-warehouse .titletable h1{
    font-size: 40px;
}

.TableCongeladores-warehouse .sticky-table-table{
   width: 100%;
   font-size: 15px;
}
.TableCongeladores-warehouse{
    margin: 0 auto;
    width: 95% !important;
}
.TableCongeladores-warehouse .titletable{
    margin-top: -30px;
}
.TableCongeladores-warehouse td:nth-child(1),
.TableCongeladores-warehouse td:nth-child(2),
.TableCongeladores-warehouse td:nth-child(3),
.TableCongeladores-warehouse td:nth-child(4){
    text-align: center;
}

.TableCongeladores-warehouse td:nth-child(5){
    text-align: left;
}
.TableCongeladores-warehouse td:nth-child(6){
    text-align: center;
}
.TableCongeladores-warehouse td:nth-child(7){
    text-align: left;
}


.search-mov-frz-war{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 13% 22% 8% 8% 7%;
    grid-template-areas:"a b c d e";
    grid-gap: 10px;
    width: 100%;
    height: 22px;
    position: relative;
    z-index: 20;
    margin-bottom: 25px !important;
}


.search-mov-frz-war input{
    height: 38px !important;
}
.search-mov-frz-war select {
    height: 24px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.search-mov-frz-war button{
  height: 38px !important;
  padding-top: 7px !important;
}

.smfw1{grid-area: a;}
.smfw1 select{
    height: 48px;
    padding: 0px;
    padding-left: 10px;
}

.smfw2{grid-area: b;}
.smfw2 select{
    height: 20px;
    padding-top: 10px;
}
.smfw2 .autocomplete-pos{
    margin-left: 10px;
    margin-top: 12px;
}
.smfw2 .autocomplete-pos input{
    padding-top: 12px;
    padding-bottom: 12px;
}

.input-num-serie input{
    height: 48px !important;
}

.smfw3{grid-area: c;}
.smfw3 input, .smfw4 input{
    margin-left: 10px;
    margin-top: 12px;
    height: 48px;
}
.smfw4{grid-area: d;}
.smfw5{grid-area: e;}
.smfw5 button{
    height: 48px;
    margin-top: 12px;
    font-size: 17px;
}

.grid-mov-almacen-agroup{
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-gap: 2%;
    grid-template-areas: 
        "a b";
}
.grid-mov-almacen-agroup-2{
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: "a";
}
.mov-almac-1-agroup{grid-area: "a";}
.mov-almac-2-agroup{grid-area: "b";}
.mov-almac-3-agroup input{
    width: 100%;
    height: 60px;
}
.text-desc-mov-agroup p{
    font-size: 18px;
}

.iconolitle{
    width: 20px !important;
}

.table-mov-frezz{
    margin-top: -45px !important;
}


.frez1 label, .frez2 label, .frez3 label, .frez4 label,
.frez5 label, .frez6 label, .frez7 label, .frez8 label,
.frez9 label, .frez10 label, .frezn0 label{
    background-color: white;
    padding-right: 5px;
}

.mov-almac-3 label, .mov-almac-1 label, .mov-almac-2 label{
    background-color: white;
    padding-right: 5px;
}

@media only screen and (max-width: 1000px){
    .MuiBox-root .css-di3982{
        margin-left: 40px;
        position: relative;
    }
}
@media only screen and (max-width: 480px){
    .MuiBox-root .css-di3982{
        overflow-y: hidden;
        overflow-x: scroll;
        margin-left: -200px;
    }
    .containerselectfrezz{
        padding-bottom: 90px;
        margin-top: -40px;
    }
    .detailsfrez p{
        width: 100%;
        overflow-x: scroll;
        height: auto;
        margin-bottom: 0px;
        padding-bottom: 5px;
        text-align: left;
    }
}
.search-mov-frz-war{
    margin-bottom: -22px !important;
}

@media only screen and (max-width: 1000px) {
    .containerselectfrezz{
        position: relative;
        width: 60%;
        margin: 0 0;
        padding-top: 0px;
        margin-top: 0px;
        display: grid;
        grid-template-columns: 25% 15% 30% 30%;
        grid-template-areas: "a x b c";
        margin-top: -45px;
    }
    .selectfres_upload button{
        margin-top: 32px !important;
        width: 80%;
    }

    .containerselectfrezz button{
        overflow: hidden;
        white-space: nowrap; 
        text-overflow: ellipsis;
        border-radius: 5px;
        margin-top: 5px;
    }
}

.content-cam{
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.video-cam-upload-frezz{
    width: 90%;
    margin: 0 auto;
}   
.button-upload-file-gsheets{
    background-color: purple;
    color: white;
    margin-top: 32px;
    border-radius: 10px;
    margin-left: 10px;
}