.TableProduct{
    width: 95% !important;
    margin: 0 auto;
    overflow-x: scroll;
}
.TableProduct .sticky-table-table{
    width: 100%;
}
.selectProduct{
    z-index: 150 !important;
}
.selectProduct:hover{
    cursor: pointer !important;
}


.upload_bottom_products{
    margin-left: 200px;
}
.upload_bottom_products button{
    background-color: rgb(56, 19, 90);
    border-radius: 10px;
    color: white;
    position: relative;
    z-index: 500 !important;
}