body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-gutter: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.icons_general_delete {
  cursor: pointer;
  color: red;
  width: .8em !important;

}
.icons_general_save {
  cursor: pointer;
  color: #6776a0;
  width: .8em !important; 
}

.table_point_of_sale_details th {
  background-color: #0c4683 !important;
}

.custom-header-cell {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.custom-sort-label {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}


button.MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled.MuiIconButton-sizeSmall.css-1so9ih7-MuiButtonBase-root-MuiIconButton-root{
  display: none;
}

.Mui-TableHeadCell-Content-Actions.MuiBox-root.css-epvm6 {
  position: absolute;
  top: -15px;
  right: -8px;
}

