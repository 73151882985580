.css-1so9ih7-MuiButtonBase-root-MuiIconButton-root {
  width: 1em !important;
  height: 1em !important;
}
.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  width: 1em !important;
  height: 1em !important;
}
.css-1cyhbc3-MuiButtonBase-root-MuiIconButton-root {
  width: 1em !important;
  height: 1em !important;
}
/* .css-1bxhrg6-MuiTableRow-root {
    background-color: #3f87a6 !important;
} */

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -10px !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
}
.MuiTooltip-tooltip {
  display: none;
  white-space: normal;
}

.controlInvoice {
  width: 70% !important;
  font-size: 10px !important;
}
.css-1p0wbhh {
  position: absolute !important;
  padding-left: 68% !important;
}



@media only screen and (max-width: 1499px) {
  .controlInvoice {
    width: 65% !important;
  }
}

.css-bbxzxe {
  color: black;
}

.css-lapokc {
  color: black;
}

.css-p0rm37 {
  transform: translate(14px, 7px) scale(0.75) !important;
}

.css-1tzeee1 {
  color: #5b5b5b !important;
  opacity: 0.4 !important;
}

.new_table_v2 table thead tr div {
  color: white !important;
}

.new_table_v2 table thead th svg {
  width: 20px;
}
.new_table_v2 table tbody td:nth-child(2) {
  text-align: center !important;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.css-slyssw {
  width: 20px !important;
  height: 20px !important;
}

button.MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled.MuiIconButton-sizeSmall.css-1j7qk7u {
  width: 20px !important;
  height: 20px !important;
}



.details_bonus_products{
  width: 95%;
  margin: 0 auto;
  padding: 10px;
}
.grid-information-product-bonus{
  display: grid;
  grid-template-columns: 40% 30% 30%;
  grid-template-areas: "a b c";
  margin-bottom: 5px;
}
.grid-information-product-bonus span{
  font-weight: bold;
}
.grid-information-product-bonus p{
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
}
.icons{
  text-align: center  ;
  margin: 0 auto;
}
.icons img{
  margin-right: 5px;
  cursor: pointer;
}
.openmenu{
  text-align: right;
  font-size: 18px;
  padding-bottom: 0px;
  margin-bottom: -10px;
  margin-right: 10px;
}
.openmenu svg{
  font-size: 35px;
  cursor: pointer;
}

@media screen and (max-width:480px) {
  .grid-information-product-bonus{
    grid-template-columns: 90%;
    grid-template-areas: "a""b""c";
    margin-bottom: 5px;
    grid-gap: 5px;
  }
}