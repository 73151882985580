.form-content{
    width: 80%;
    margin: 0 auto;
    padding-bottom: 20px;
}
.formgrid{
    width: 100%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-areas: "a b c";
    grid-gap: 5%;
}
.formgrid2{
    width: 100%;
}
.formgrid2 textarea{
    width: 100%;
    margin-left: 1.5%;
    margin-top: 10px;
    border-radius: 5px;
    height: 100px;
    max-height: 100px;
    padding: 10px;
}

.upload-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    height: 40px;
    font-size: 18px;
}
.upload-button-pdf{
    display: inline-block;
    padding: 10px 20px;
    background-color: #581208;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    height: 40px;
    font-size: 18px;
}

.upload-register{
    display: inline-block;
    padding: 10px 20px;
    background-color: green;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    height: 40px;
    font-size: 18px;
}
.upload-cancel{
    display: inline-block;
    padding: 10px 20px;
    background-color: red;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    height: 40px;
    font-size: 18px;
}

.gridfiles{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "a b";
    width: 97%;
    margin: 0 auto;
    padding-top: 10px;
    grid-gap: 3%;
}
.uploadimg p{
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}
.text-file-dat{
    display: flex;
}


.form-content{
  width: 90%;
  margin: 0 auto;
  
  text-align: center; 
}
.users{
    width: 100%;
    margin: 0 auto;
}
.form-content .users .list-users{
    width: 98%;
    margin: 0 auto;
    margin-top: 20px;  
}
.form-content .users h2{
    font-size: 20px;
    text-align: center;
    color: black;
    margin-top: 20px;
}
.form-content .users .list-users{
    border: 1px solid gray;
    border-radius: 10px;
    padding: 10px;
    height: auto;
    max-height: 150px;
    overflow-y: auto;
}

.form-content .users .list-users p{
    font-size: 18px;
}
.form-content .users .list-users svg{
    color: red;
    margin-right: 10px;
    cursor: pointer;
}

.user-select-send-email{
    display: flex;
}

.form-secctions .form{
    padding-top: 20px;
    width: 90%;
    margin: 0 auto;
}
.form-secctions .form .items{
    margin-bottom: 20px !important;
}

.load-sendmail{
    width: 90%;
    margin: 0 auto;
    text-align: center;
}
.load-sendmail img{
    width: 50%;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.load-sendmail h2 {
    text-align: center;
    width: 100%;
    font-size: 25px;
    color: #134a85;
    font-weight: bold;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 20px;
}