.content-forms-contests{
    width: 90%;
    margin: 0 auto;
    position: relative;
}

.custom-modal{
    width: 100%;
    margin: 0 auto;
}
.grid1-contests{
    width: 100;
    display: grid;
    grid-template-columns: 46% 46%;
    margin: 0 auto;
    grid-template-areas: "a a";
    grid-gap: 10px;
}

.grid2-contests{
    width: 100;
    display: grid;
    grid-template-columns: 46% 46%;
    margin: 0 auto;
    grid-template-areas: "a b";
    grid-gap: 10px;
}

.contests-g1{
    grid-area: a;
}
.contests-g2{
    grid-area: a;
}
.contests-g3{
   grid-area: b; 
}
.contests-g4{
    grid-area: a;
}
.contests-g5{
   grid-area: b; 
}

.contests-g4 select{
    height: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 3px;
    margin-bottom: 0px;
}

.grid-concurse-items-edit{
    display: grid;
    grid-template-columns: 60% 35%;
    grid-template-areas: "a b";
    grid-gap: 10px;
    width: 100%;
    margin: 0 auto;
}
.form-concurs-updates-inputs{
    width: 90%;
    margin: 0 auto;
}
.buttonactionsdetailstickets{
    width: 95%;
    margin: 0 auto;
}
.route-list-contests{
    width: 97%;
}
.route-list-contests select{
    padding-top: 5px;
    padding-bottom: 5px;
}