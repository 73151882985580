.cont-form-payr{
    width: 90%;
    margin: 0 auto;
    text-align: center !important;
}
.cont-form-payr label{
    font-size: 20px;
    background-color: white;
    margin-top: -5px !important;
}
.cont-form-payr input{
    height: 50px;
}
.cont-form-grid-2{
    display: grid;
    grid-template-columns: 25% 60% 14%;
    grid-template-areas: "a b c";
    grid-gap: 10px;
    margin: 0 auto;
    width: 100%;
}
.conforg3{
    margin-left: 15px;
    padding-top: 12px;
}
.conforg3 input{
    padding-top: 0px;
    padding-bottom: 0px;
    height: 32px !important;
}
.conforg5{
    margin-left: 15px;
    padding-top: 12px;
}
.conforg5 input{
    padding-top: 0px;
    padding-bottom: 0px;
    height: 32px !important;
}
.conforg4{
    padding-top: 12px;
}

.conforg4 input{
    padding-top: 0px;
    padding-bottom: 0px;
    height: 42px !important;
}

.conforg5 label, .conforg3 label, .conforg4 label{
    font-size: 20px !important;
}   
.cont-form-grid-3{
    display: grid;
    grid-template-columns: 80% 20%;
    grid-template-areas: "a b";
    grid-gap: 10px;
    margin: 0 auto;
    width: 100%;
}

.conforg5{
    margin-right: 10px;
}
.conforg6{
    padding-top: 12px;
}


@media screen and (max-width: 480px) {
    .cont-form-grid-2{
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas: "a""b""c";
        grid-gap: 0px;
        width: 100%;
    }
    .cont-form-grid-3{
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas: "a""b";
        grid-gap: 0px;
        width: 100%;
    }

    .conforg3{
        width: 99%;
    }
    .conforg4{
        width: 99%;
        margin-top: 10px;
        margin-left: 15px;
    }
    .conforg5{
        margin-top: 10px;
        width: 98%;
    }
    .conforg6{
        margin-top: 10px;
        width: 99%;
        margin-left: 15px;
    }
}