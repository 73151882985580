.modal-create-case {
  width: 90%;
  max-width: 1000px;
  position: fixed !important;  
  font-size: 1.3em;
  text-align: center;
}

.modal-content {
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #fff;
  width: auto;
  max-width: 100%;
  padding: 0px;
  border-radius: 4px;
  outline: none;
  text-align: inherit;
}

.modal-header {
  display: flex;
  flex-direction: row;
  flex: 100%;
  justify-content: space-between;
}

.p1 {
  grid-area: p1;
  background-color: rgb(228, 227, 227);
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin: 0% 5px 0% 20%;
  text-align: center;
  border: 1px solid black;
}

.p2 {
  grid-area: p2;
  background-color: rgb(228, 227, 227);
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin: 0% 20% 0% 5px;
  text-align: center;
  border: 1px solid black;
}

.modal-close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.checkbox-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.checkbox-item {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  width: 50%;
  padding-inline: 10px;
  margin: 5px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.helper-check{
  color: red;
  font-style: italic;
  font-weight: lighter;
}

.backdrop{
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  width:100%;   
  background-color: white;
  z-index: 10020;
}

.select-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;  
  text-align: start;
}
 
.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 20px;
}

.breadcrumb {
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
}

.form-item {
  width: 100%;
  margin-inline: 0;
  margin-bottom: 15px;
  
}

.label-select {
  background-color: #fff;
  font-weight: bold;
  font-size: large;
  
}

.step-content{
  padding: 0px 5%;
  width: 100%;
  justify-content: center;
  text-align: center;
}
.checkbox-title {
  width: 100%;
  justify-self: center;
  align-content: center;
}

.carrusel-container {
  width: 100%;
  overflow-x: auto;
}

.carrusel-img {
  display: flex; 
  overflow-x: auto;
}

.img-support {
  margin-right: 10px; 
  flex-shrink: 0; 
  position: relative;
}
.img-support img{
  width: 200px !important;
}
.img-support .img-delet{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  height: 30px !important;
  width: 30px !important;
}
.img-support .img-delet:hover{
  cursor: pointer;
}
.input-img-file{
  width: 146px !important;
}
.form-item-img{
  text-align: left;
}

.list-files-upload-ticket{
  width: 100%;
  height: auto;
  max-height: 80px;
  padding: 10px;
  background-color: rgb(237, 237, 237);
  border-radius: 5px;
  overflow-y: auto;
}
.list-files-upload-ticket 
.list-files-upload-items{
  width: 100%;
  margin: 0 auto;
}
.list-item-descripcion{
  display: grid;
  grid-template-columns: 10% 90%;
  grid-template-areas: "a b";
}
.list-item-descripcion p {
  display: flex;
  width: 100%;
  align-items: center;
  white-space: nowrap;
  font-size: 18px;
}
.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 50px);
  display: inline-block;
  vertical-align: bottom;
}
.file-extension {
  flex-shrink: 0;
}
.buttons-actions{
  display: flex;
  text-align: right;
}
.buttons-actions svg{
  font-size: 25px;
  cursor: pointer;
}
.icon-svg-del{
  color: rgb(223, 8, 8);
}

.list-files-description-ticket{
  background-color: rgb(192, 192, 192);
  margin: 0 auto;
  overflow-y: auto;
  height: 100px;
}
.content-view-files-description{
  position: relative;
  height: 60px;
}
.list-files-upload-ticket-decription{
  width: 150%;
  min-width: 150%;
}
.list-item-descripcion-des{
  display: grid;
  grid-template-columns: 10% 90%;
  grid-template-areas: "a b";
}
.list-item-descripcion-des p {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 18px;
}