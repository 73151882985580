
/* Tabla de recuperar socio de negocios */
.Tablerecoveryb{
    margin: 0 auto;
    width: 95% !important;
  }
.tablerecposbus{
  margin-top: -70px;
}
  .Tablerecoveryb .sticky-table-table{
    width: 100%;
  }
  .Tablerecoveryb td:nth-child(1){
    min-width: 100px;
    text-align: center; 
  }
  .Tablerecoveryb td:nth-child(2){
    text-align: center;
  }
  .Tablerecoveryb td:nth-child(3){
    text-align: left;
  }
  .Tablerecoveryb td:nth-child(4),
  .Tablerecoveryb td:nth-child(6){
    text-align: center;
  }
  .Tablerecoveryb td:nth-child(5){
    text-align: left;
  }
  
  .Tablerecoveryb th:nth-child(5) #custom-select{
    width: 100% !important;
    height: 30px;
    font-size: 15px !important;
    text-align: center; 
  }
  
  .Tablerecoveryb .titletable {
    margin-top: -50px;
    margin-bottom: -40px;
  }
  
  .gridrecovery01{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:"a a a b b b";
    grid-gap: 10px;
  }
  .recov1{grid-area: a;}
  .recov2{grid-area: b;}
  .gridrecovery02{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:
      "a a b b c c";
    grid-gap: 10px;
  }
  .recov3{grid-area: a;}
  .recov4{grid-area: b;}
  .recov5{grid-area: c;}
  
  .gridrecovery03{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:
      "a a a b b b";
    grid-gap: 10px;
  }
  .recov6{grid-area: a;}
  .recov7{grid-area: b;}
  
  .gridrecovery04{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:
      "a a b b c c";
    grid-gap: 10px;
  }
  .recov8{grid-area: a;}
  .recov9{grid-area: b;}
  .recov10{grid-area: c;}
  
  @media only screen and (max-width: 720px) {
    .gridrecovery01{
      grid-template-areas:
       "a a a a a a"
       "b b b b b b";
    }
    .gridrecovery02{
      grid-template-areas:
       "a a a a a a"
       "b b b b b b"
       "c c c c c c";
    }
    .gridrecovery03{
      grid-template-areas:
       "a a a a a a"
       "b b b b b b";
    }
    .gridrecovery04{
      grid-template-areas:
       "a a a a a a"
       "b b b b b b"
       "c c c c c c";
    }
  }


  
  @media only screen and (max-width: 480px) {
    .gridrecovery01{
      grid-template-columns: 95%;
      grid-template-areas:
       "a"
       "b";
      width: 100%;
      margin: 0 auto;
    }
    .gridrecovery02{
      grid-template-areas:
       "a"
       "b"
       "c";
      grid-template-columns: 95%;
      width: 100%;
      margin:0 auto;
    }
    .gridrecovery03{
      grid-template-areas:
       "a"
       "b";
      grid-template-columns: 95%;
      width: 100%;
      margin: 0 auto;
    }
    .gridrecovery04{
      grid-template-areas:
       "a"
       "b"
       "c";
      grid-template-columns: 95%;
      width: 100%;
      margin: 0 auto;
    }
  }