.img-muestra-upload{
    position: absolute;
    z-index: 200;
    top: 0;
}

.botton-archivos svg{
    color: rgb(15, 15, 89);
    font-size: 45px;
    padding-top: 5px;
}
.botton-archivos svg:hover{
    cursor: pointer;
    color: rgb(42, 86, 131);
}

.botton-img-clic svg{
    color: rgb(15, 15, 89);
    font-size: 45px;
    padding-top: 5px;
}
.botton-img-clic svg:hover{
    cursor: pointer;
    color: rgb(42, 86, 131);
}
.botton-audio svg{
    color: rgb(15, 15, 89);
    font-size: 45px;
    padding-top: 5px;
}
.botton-audio svg:hover{
    cursor: pointer;
    color: rgb(42, 86, 131);
}

.input-multimedia{
    display: grid;
    height: 50px;
    grid-template-columns: 33% 33% 33%;
    grid-template-areas: "a b c";
}
/* jsx de upload file */
.icon-uplod-file svg{
    color: rgb(15, 15, 89);
    font-size: 45px;
    padding-top: 5px;
    margin-right: 10px !important;
}
.icon-uplod-file svg:hover{
    cursor: pointer;
    color: rgb(42, 86, 131); 
}

.botton-audio{
    width: 40px;
}
.sound-wave{
    display: none !important;
}