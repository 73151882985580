/*containerFactureResponse-1 fact-1... fact-5 */
.containerProducts {
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}
.bp-1 {
  grid-area: cp1;
}
.bp-2 {
  grid-area: cp2;
}
.bp-3 {
  grid-area: cp3;
}
.bp-4 {
  grid-area: cp4;
}
.bp-5 {
  grid-area: cp5;
}
.bp-6 {
  grid-area: cp6;
}
.bp-7 {
  text-align: center;
  grid-area: cp7;
}

.bp-8 {
  grid-area: cp8;
}
.bp-9 {
  grid-area: cp9;
}
.bp-10 {
  grid-area: cp10;
}
.bp-11 {
  grid-area: cp11;
}
.bp-12 {
  grid-area: cp12;
}
.bp-13 {
  grid-area: cp13;
}
.bp-14 {
  grid-area: cp14;
}
.bp-15 {
  grid-area: cp15;
}
.bp-16 {
  grid-area: cp16;
}
.bp-17 {
  grid-area: cp17;
}
.bp-18 {
  grid-area: cp18;
}
.bp-19 {
  grid-area: cp19;
}
.bp-20 {
  grid-area: cp20;
}

.bp-21 {
  grid-area: cp21;
}

.bp-22 {
  grid-area: cp22;
}

.bp-23 {
  grid-area: cp23;
}

.bp-24 {
  grid-area: cp24;
}
.bp-25 {
  grid-area: cp25;
  text-align: center;
}
.bp-4n{
  grid-area: cp26;
}

.bp-10b{
  grid-area: cp10b;
}
.bp-10b input{
  height: 40px !important;
}


.containerProducts {
  display: grid;
  grid-template-areas:
    "cp1 cp2 cp2 cp3 cp3 cp3"
    "cp4 cp26 cp5 cp5 cp6 cp6"
    "cp12 cp10b cp10b cp7 cp8 cp9"
    "cp10 cp10 cp10 cp11 cp11 cp11";
  gap: 10px;
}
p.validate_data {
  color: red !important;
  text-align: "justify";
  margin: 0 auto;
  width: 90%;
}

.containerProducts02 {
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}
.containerProducts03 {
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}
.containerProducts02 {
  display: grid;
  grid-template-areas:
    "cp1 cp2 cp2 cp2 cp9 cp3"
    "cp4 cp4 cp4 cp5 cp5 cp6"
    "cp7 cp7 cp7 cp8 cp8 cp8";
  gap: 10px;
}

.containerProducts03 {
  display: grid;
  grid-template-areas:
    "cp1 cp1 cp2 cp2 "
    "cp3 cp3 cp4 cp4"
    "cp7 cp7 cp8 cp8";
  gap: 10px;
}

.modalProduct{
  max-width: 80% !important;
}

.AddData{
  width: 150px;
  cursor: pointer;
}

.TableProduct thead th:nth-child(1){
  white-space: normal !important;
  width: 80 !important;
  max-width: 80 !important;
}
.TableProduct td th:nth-child(1){
  white-space: normal !important;
  width: 80 !important;
  max-width: 80 !important;
}

.AddDatapointer{
  cursor: pointer;
}


.selectProduct{
  width: 150px;
  position: absolute;
  margin-left: 2.8%;
  margin-top: -2px !important;
  font-size: 18px;

}
.selectProduct select{
  height: 40px;
}


@media screen and (max-width:480px) {
  .navitation_products_crud{
    width: 90%;
    margin: 0 auto;
    overflow-x: scroll;
  }

  .containerProducts {
    width: 90%;
    grid-template-columns: 33% 33% 33%;
    display: grid;
    grid-template-areas:
      "cp1 cp1 cp1" 
      "cp2 cp2 cp2"
      "cp3 cp3 cp3"
      "cp4 cp4 cp4" 
      "cp26 cp26 cp26" 
      "cp5 cp5 cp5" 
      "cp6 cp6 cp6"
      "cp12 cp12 cp12"
      "cp10b cp10b cp10b"
      "cp7 cp7 cp8"
      "cp9 cp9 cp9"
      "cp10 cp10 cp10"
      "cp11 cp11 cp11";
    gap: 0px;
  }

  .containerProducts02 {
    width: 90%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
      "cp1""cp2""cp9""cp3"
      "cp4""cp5""cp6"
      "cp7""cp8";
    gap: 0px;
  }
  
  .containerProducts03 {
    width: 90%;
    grid-template-columns: 100%;
    display: grid;
    grid-template-areas:
      "cp1""cp2 "
      "cp3""cp4"
      "cp7""cp8";
    gap: 0px;
  }
}