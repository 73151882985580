.search-bar {
  display: flex;
  align-items: center;
  background-color: #112031;
  border: 1px solid #1853a7;
  border-radius: 8px;
  padding: 0px 10px;
  color: white;
}

.search-bar input {
  flex: 1;
  border: none;
  background-color: transparent;
  color: white;
  outline: none;
  font-size: 16px;
  padding-left: 10px;
}

.search-bar input::placeholder {
  color: #ccc;
}

.icon-search {
  color: #ccc;
  font-size: 22px;
}

.search-results {
  background-color: #1a374d;
  border: 1px solid #1853a7;
  border-radius: 0 0 8px 8px;
  position: absolute;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  z-index: 10;
}

.search-results-header {
  color: #c7d0d8;
  font-size: 14px;
  margin-bottom: 10px;
}

.category-section {
  margin-bottom: 10px;
}


.category-header {
  color: #ffffff !important;
  font-size: 14px;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.category-list {
  list-style: none;
  padding-left: 0;
}

.category-item {
  margin: 5px 0;
  background-color: #214a5c;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.category-item:hover {
  background-color: #1853a7;
}

.category-link {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.category-link span {
  margin-left: 8px;
  font-weight: bold;
}

.no-results {
  color: white;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}

.search-container ::-webkit-scrollbar {
  width: 8px;
}

.search-container ::-webkit-scrollbar-thumb {
  background: #d6d6d6;
  border-radius: 5px;
}

.search-container ::-webkit-scrollbar-thumb:hover {
  background: #ffa500;
}

.search-container ::-webkit-scrollbar-track {
  background: #1a374d;
}


