
@media only screen and (max-width: 1000px) {
  .content-minwid-menu{
    height: 100%;
    width: 400px;
    background-color: white;
    padding: 10px;
    z-index: 150 !important;
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid rgb(145, 145, 145);
    margin-right: -20px;
    margin-top: -10px;
  }
  
  .back-mini-menu{
    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-areas: "a b";
    padding-bottom: 10px;
  }
  .back-mini-menu svg{
    font-size: 30px;
    cursor: pointer;
  }
  .back-mini-menu h2{
    font-size: 20px;
    font-weight: bold;
  }
  .search-mov-frz-war{
    display: block !important;
    min-width: 90%;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    height: auto;
}
.search-mov-frz-war .smfw5 button{
    width: 100%;
    border-radius: 5px;
}
}


@media only screen and (max-width: 720px) {
  .back-mini-menu{
    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-areas: "a b";
    padding-bottom: 10px;
  }
  .back-mini-menu svg{
    font-size: 30px;
    cursor: pointer;
  }
  .back-mini-menu h2{
    font-size: 20px;
    font-weight: bold;
  }
}
@media only screen and (max-width: 480px) {

    .content-minwid-menu{
      height: 100%;
      width: 300px;
      background-color: white;
      padding: 10px;
      z-index: 150 !important;
      position: absolute;
      top: 0;
      right: 0;
      border: 1px solid rgb(145, 145, 145);
    }
    .controlInvoice{
      display: block !important;
      min-width: 100%;
    }
    
    .controlInvoice{
      line-height: 60px;
    }
    .controlInvoice div{
      width: 100%;
    }
    .search-mov-frz-war{
        display: block !important;
        min-width: 90%;
        width: 90%;
        margin: 0 auto;
        text-align: center;
        height: auto;
    }
    .search-mov-frz-war .smfw5 button{
        width: 100%;
        border-radius: 5px;
    }
}


@media only screen and (min-width: 750px) and (max-width: 1000px){
  .css-14vyde3-MuiCollapse-wrapperInner{
    display: none;
  }
}