.new_chat_container_mss{
    width: 100%;
    height: 600px;
    position: relative;
}
.title-new-chat-mss{
    width: 100%;
    height: 60px;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: "a b";
    position: sticky;
    background-color: #1a374d;
    top: 0;
    z-index: 100;
}
.title-new-chat-mss h2{
    font-size: 20px;
    color: white;
    text-align: left;
    padding-top: 7px;
    padding-left: 15%;
}
.title-new-chat-mss svg{
    color: white;
    font-size: 35px;
    padding-left: 10px;
    padding-top: 10px;
}
.title-new-chat-mss svg:hover{
    cursor: pointer;
}

.contactos-mss-chat{
    width: 100%;
    position: relative;
}
.contact-ind-mss{
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
}
.line{
    background-color: rgb(156, 156, 156);
    height: 1px;
    width: 90%;
    margin: 0 auto;
}
.div-grid-contact-ind{
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: "a b";
    padding: 10px;
}
.div-grid-contact-ind .circle{
    background-color:#1a374d;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}
.div-grid-contact-ind .info-contac{
    text-align: left;
}
.contact-ind-mss:hover{
    background-color: rgb(182, 182, 182);
    cursor: pointer;
}
.loading-contc{
    padding-top: 20%;
}
.loading-contc p{
    color: #1a374d;
    font-size: 20px;
    text-align: center;
}