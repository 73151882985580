.TableCOnsultaClientes {
  margin: 0 auto;
  width: 95%;
  margin-top: -50px; 
  overflow-x: scroll;
}
.TableCOnsultaClientes .sticky-table-table{
  width: 100%;
}
/* CUERPO TABLA */
.TableCOnsultaClientes td:nth-child(3){ 
  text-align: center;
}
.TableCOnsultaClientes td:nth-child(4){
  text-align: justify;
}

.TableCOnsultaClientes td:nth-child(1),
.TableCOnsultaClientes td:nth-child(2),
.TableCOnsultaClientes td:nth-child(5),
.TableCOnsultaClientes td:nth-child(7),
.TableCOnsultaClientes td:nth-child(8),
.TableCOnsultaClientes td:nth-child(9),
.TableCOnsultaClientes td:nth-child(10){
  text-align: center;
}


.TableReport thead th:nth-child(4),
.TableReport td:nth-child(4){
  max-width: 150px!important;
}



/* RESPONSE TABLA */

@media only screen and (max-width: 1390px) {
  .TableCOnsultaClientes  {
      font-size: 13px;
      width: 97%;

  }
}

@media only screen and (max-width: 1320px) {
  .TableCOnsultaClientes  {
      font-size: 12px;
      width: 97%;

  }
}

@media only screen and (max-width: 1059px) {
    .TableCOnsultaClientes  {
        font-size: 9.5px;
        width: 98%;

    }
}

@media only screen and (max-width: 890px) {
    .TableCOnsultaClientes  {
        font-size: 8.5px;
    }
    .TableCOnsultaClientes{
        width: 100%;
    }
}

.cursorPointer{
  cursor: pointer;
}

.modalSetBussnes{
  max-width: 70% !important;
}




.modalSetBussnes .Modal-module_modalBody__3_RoW{
  overflow-y: unset !important;

}
.switchRegister{
  padding-left: 20px;
  padding-top: 10px;
}

.Modal-module_modal__FqeFw{
  overflow:unset !important;
}

body{
  --st-overlay-perspective:none !important;
}

.idAler{
  background-color: black !important;
}




.alertNotification{
  color: white !important;
  font-size: 25px !important;
  
}

.alertNotificationP{
  color: white !important;
}

.iconctnAlert{
  margin-top: 5px;
  width: 70%;
}


.ctnAler { grid-area: 1 / 1 / 3 / 2; }
.ctnAler2 { grid-area: 1 / 2 / 2 / 4; }
.ctnAler3 { grid-area: 2 / 2 / 3 / 4; }


.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-12ogz78-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
  opacity: unset !important;
  -webkit-text-fill-color: black !important;
}



.css-1kw4qaw-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: white !important;
  box-shadow: none !important;
}

.containerDrivers {
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}


.bp-5-1{
  grid-area: cp5;
  padding-top: 10px;
  padding-left: 16px;
}

.containerDrivers {
  display: grid;
  grid-template-areas:         
                  "cp1 cp1 cp1 cp7 cp7 cp7"
                  "cp5 cp5 cp5 cp5 cp6 cp9"
                  "cp10 cp8 cp8 cp8 cp8 cp8"
                  "cp2 cp2 cp3 cp3 cp4 cp4"
                  "cp21 cp21 cp21 cp22 cp22 cp22";
  gap: 10px;
}




.containerVehicles {
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}


.bp-5-1{
  grid-area: cp5;
  padding-top: 10px;
  padding-left: 16px;
}

.containerVehicles {
  display: grid;
  grid-template-areas:         
                  "cp1 cp1 cp1 cp7 cp7 cp7"
                  "cp11 cp11 cp11 cp12 cp12 cp12"
                  "cp13 cp13 cp13 cp13 cp13 cp13"
                  "cp5 cp5 cp5 cp5 cp6 cp10"
                  "cp30 cp8 cp8 cp8 cp8 cp8"
                  "cp9 cp9 cp14 cp14 cp15 cp15"
                  "cp2 cp2 cp3 cp3 cp4 cp4"
                  "cp21 cp21 cp21 cp22 cp22 cp22";
  gap: 10px;
}

.bp-13ext{
  margin-right: 100px;
}