.chat-container{
    width: 100%;
    margin: 0 auto;
}
.grid-chat{
    display: grid;
    grid-template-columns: 35% 65%;
    grid-template-areas: "a b";
    width: 80%;
    margin: 0 auto;
    height: 80vh;
}
.chats-archivados{
    height: auto;
    border: 1px solid gray;
    overflow-y: scroll;
}
.chats-online{
    width: 100%;
    display: grid;
    grid-template-rows: 60px 75% 15%;
    grid-area: 
    "a"
    "b"
    "c";
    height: 80vh;
    border: 1px solid gray;
}
/* ------------------ chat portada--------------------------*/
.chats-portada{
    border: 1px solid gray;
}
.chats-portada .container-chat-portada{
    width: 80%;
    margin: 0 auto;
    padding-top: 30px;
}
.container-chat-portada img{
    width: 40%;
}
.text-portada h2{
    font-size: 30px;
    font-weight: bold;
}
.text-portada p{
    font-size: 20px;
}


/*-------------------- chat abierto ----------------------*/

/* cabecera de chat */
.chat-actual{
    display: flex;
    padding: 10px;
    background-color: rgb(237, 237, 237);
}
.chat-actual .circle{
    background-color: black;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}
.chat-actual p{
    font-size: 18px;
    padding-left: 20px;
}
/* conversacion */
.mensaje-link{
    font-size: 20px;
    color:rgb(43, 43, 123) !important;
    text-decoration: underline;
}

.conversation{
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
    overflow-y: scroll;
    /* background-image: url("../imgComponents/iconos/fondo.png"); */ 
    /* background-color: rgb(227, 227, 227); */
    background-color: rgb(168, 168, 168);
    background-size: cover;
    background-position-x: 50%;
    position: relative;
    width: 100%;
}
.fondo-chats{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}
.iniciar-chat{
    height: 20% !important;
    padding: 20px;
    /* display: flex; */
    display: grid;
    grid-template-areas: "a b c";
    grid-template-columns: 15% 80% 10%;
    width: 100%;
}
.input-multimedia{
    grid-area: a;
}
.input-chat{
    grid-area: b;
}
.input-chat {
   border-radius: 10px;
   padding: 10px;
   font-size: 20px;
   max-height: 70px;
}
.text-n{
    font-size: 18px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    width: 95%;
    margin: 0 auto;
}


.buttton-send-icon{
    grid-area: c;
}
.iniciar-chat button{
    width: 20%;
    height: 50px;
    background-color: rgb(9, 9, 118);
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.messages-container{
    display: grid;
    grid-template-columns: 25% 10% 15% 15% 10% 25%;
    grid-template-areas: 
        "a a a a b b"
        "c c d d d d";
    padding-bottom: 15px;
}
.mss-emisor{
    font-size: 18px;
    color: black;
    padding-bottom: 20px;
    grid-area: d;
    border: 1px solid gray;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.816);
    word-wrap: break-word;
    overflow: hidden;
}
.mss-receptor{
    font-size: 18px;
    color: black;
    padding-bottom: 20px;
    grid-area: a;
    border: 1px solid gray;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    text-align: left;
    background-color: rgba(193, 209, 255, 0.816);
    word-wrap: break-word;
    overflow: hidden;
}
.fecha{
    text-align: right;
    font-size: 15px;
    color: rgb(106, 106, 106);
    padding-bottom: 0%;
    margin-bottom: 0%;
}
.chats-archivados p{
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: black;
}
.chats-archivados .conversations{
    width: 100%;
    height: 80px !important;
    padding: 10px;
    background-color: white;
    margin-bottom: 10px;
}
.user-logo{
    grid-area: "a";
}

.correo-bandeja{
    width: 90%;  
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
.chats-archivados .conversations:hover{
    background-color: rgb(145, 159, 255);
    border-radius: 15px;
    cursor: pointer;
}
.messages-container:last-child {
  margin: 0 auto;
}
.configuracion{
    height: 60px;
    background-color: rgb(237, 237, 237);
}
.search-user{
    height: 60px;
    display: flex;
    padding: 10px;
    margin-bottom: 10px;
}
.search-user input{
    height: 50px;
    font-size: 20px;
    border: none;
    padding: 10px;
}
.search-user svg{
    color: gray;
    font-size: 40px;
    margin-top: 5px;
}
.recordgif{
    text-align: center;
    display: flex;
    background-color: red;
}
.recordgif img{
    width: 80% !important;
    filter: brightness(0) !important;
}
.upload-file svg{
    position: absolute;
    right: 0;
    margin-right: 20px;
    font-size: 30px !important;
    color: red;
}
.upload-file svg:hover{
    cursor: pointer;
    color: rgb(140, 19, 19) !important;
}




.configuracion{
    width: 100%;
    display: grid;
    grid-template-columns: 10% 40% 20% 18% 5%;
    grid-template-areas: "a b c d e";
}
.new_mss_icon{
    grid-area: "d";
    padding-top: 15px;
}
.new_mss_icon svg{
    font-size: 30px;
}
.ppp{
    grid-area: "e";
    padding-top: 10px;
    text-align: right;
}
.ppp svg{
    font-size: 40px;
}

.upload-img{
    height: 270px;
    background-color: rgba(0, 0, 0, 0.511);
    position: relative;
    z-index: 150;
    width: 100%;
    margin-top: -355px;
    padding: 10px;
}
.upload-img img{
    height: 100%;
}
.upload-file{
    height: 60px;
    background-color: rgba(255, 255, 255, 0.677);
    position: relative;
    z-index: 150;
    width: 100%;
    margin-top: -145px;
    padding: 10px;
    display: flex;
    padding-left: 30px;
}
.upload-file p{
    font-size: 20px;
    color: black;
    text-align: left;
    padding-top: 5px;
    padding-left: 20px;
}
.upload-file img{
    width: 40px;
}

.mensaje-files{
    display: flex;
}
.mensaje-files img{
    width: 30px;
    margin-right: 10px;
    max-height: 30px !important;
}
.mensaje-files a{
    font-size: 20px;
    color: rgb(51, 51, 51) !important;
    text-decoration: underline;
    width: 95%;
    overflow-x: hidden;
}

/* audio */
.upload-audio-mp3{
    text-align: center;
}

.upload-audio-mp3 audio{
    width: 90% !important;
    height: 40px;
    text-align: center;
}


.buttton-send-icon{
    font-size: 45px !important;
    color: rgb(15, 15, 89);
    padding-top: 5px;
    margin-left: 5px;
}
.buttton-send-icon:hover{
    cursor: pointer !important;
    color: rgb(42, 86, 131);
}
.fecha-conversation{
    margin: 0 auto;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.571);
    width: 80px;
    border-radius: 5px;
    color: black;
    font-weight: bold;
    margin-bottom: 10px;
}
.upload-img svg{
    color: rgb(255, 255, 255);
    position: absolute;
    top: 0;
    z-index: 200px;
    margin-left: -15px;
    font-size: 30px;
    background-color: rgb(255, 0, 0);
    border-radius: 25px;
}
.upload-img svg:hover{
    cursor: pointer;
    background-color: brown;
}

.new_mss_icon:hover{
    cursor: pointer;
    color: rgb(13, 13, 83);
}
.ppp:hover{
    cursor: pointer;
    color: rgb(13, 13, 83);
}

