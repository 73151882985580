.tableposprincipal{
    margin-top: -40px;
}  
.TablePointOfSales .containerCutBox{
    margin-top: -25px;
}
.TablePointOfSales .contanerFIltersTableSelect{
    margin-bottom: 20px;
}

.containerPointsale {
    display: grid;
    grid-template-areas: "expen1 expen2 expen3 expen4 expen5 expen8";
    gap: 10px;
    position: relative;
    width: 90%;
    z-index: 10;
    
  }

  .button-upload-csv-address{
    width: 200px;
    background-color: #214e7d;
    text-align: center;
    border: none;
    border-radius: 10px;
    padding: 10px;
    color: white;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    z-index: 15;
}

.button-upload-csv-address svg{
    margin-right: 10px;
    font-size: 25px;

}

@media screen and (max-width:480px) {
    .button-upload-csv-address{
        font-size: 0px;
        width: 40px;
        padding-left: 7px;
    }
}