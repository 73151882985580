.containerStockv2{
    width: 60%;
    display: grid;
    grid-template-columns: 25% 20% 50%;
    grid-template-areas: "a b c";
    grid-gap: 10px;
    position: relative;
    z-index: 200 !important;
    height: 30px;
    margin-top: -10px;
}
.containerStockv2 .pdc-1{
    grid-area: a;
}
.containerStockv2 .pdc-2{
    grid-area: b;
}
.containerStockv2 .pdc-3{
    grid-area: c;
}

@media screen and (max-width:480px) {
    .containerStockv2{
        min-width: 95%;
        margin: 0 auto;
        inline-size: 30px;
        display: block;
        text-align: center;
    }
    .containerStockv2 button{
        width: 90%;
        margin-bottom: 20px;
    }
}