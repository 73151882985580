.route-89-config{
    position: relative;
}

.route-89-config .button-select-route-89 {
    width: 100px !important;
    height: 40px !important;
    color: white;
    border: none;
    border-radius: 10px;
}
.route-89-config .mapboxgl-popup{
    width: 300px !important;
    min-width: 300px !important;
}
.route-89-config .mapboxgl-popup span{
    font-weight: bold;
}

.route-89-config .mapboxgl-popup p{
    font-size: 15px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    text-align: justify;
}

.custom-menu-report-route-89{
    width: 98%;
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10%;
    grid-area: "a b c d e f g""i j k";
    grid-gap: 10px;
    margin: 0 auto;
}

.update-point-group-color{
    width: 90%;
    margin: 0 auto;
    padding-bottom: 20px;
}
.content-symbology-info .close-icon-map{
    color: white;
}

.symbology-route{
    width: 220px;
    background-color: white;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    margin-top: 10px;
    margin-left: 10px;
    height: auto;
    max-height: 300px;
    overflow-y: auto;
}
.content-symbology-sectors-filter{
    width: 220px;
    background-color: white;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    margin-top: 10px;
    margin-left: 250px;
    height: auto;
    max-height: 300px;
    overflow-y: auto;
}
.input-search-points-map-89{
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    width: 300px;
    margin-top: 11px;
    margin-right: 60px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 0 2px 2px rgba(21, 21, 21, 0.5);
}


.symbology-route h2, .content-symbology-sectors-filter h2{
    font-size: 20px;
    font-weight: bold;
}
.symbology-route .circle{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}
.symbology-route .content-symbology-info{
    width: 95%;
    margin: 0 auto;
    display: flex;
}
.symbology-route .content-symbology-info p{
    font-size: 14px;
    padding-left: 5px;
    padding-top: 2px;
    font-weight: bold;
    text-align: left;
}

.content-symbology-sectors-filter .content-symbology-info{
    width: 95%;
    margin: 0 auto;
    display: flex;
}
.content-symbology-sectors-filter .content-symbology-info p{
    font-size: 14px;
    padding-left: 5px;
    padding-top: 2px;
    font-weight: bold;
    text-align: left;
}

.content-symbology-sectors-filter .circle{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}




.button-showmapa-hover:hover{
    background-color: #630971 !important;
}

.button-filternon-hover{
    background-color: orange !important;
}
.button-filternon-hover:hover{
    background-color: rgb(218, 153, 34) !important;
}

.button-save-hover{
    background-color: gray !important;
}
.button-save-hover:hover{
    background-color: rgb(85, 85, 85) !important;
}

.button-reset-hover{
    background-color: red !important;
}
.button-reset-hover:hover{
    background-color: rgb(201, 7, 7) !important;
}
.button-sumary-hover{
    background-color: rgb(0, 166, 255) !important;
}
.button-sumary-hover:hover{
    background-color: rgb(5, 131, 200) !important;
}

.sumary-map-route89{
    position: relative;
}
.show-points-dv{
     position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    width: 300px;
    margin-top: 80px;
    margin-right: 60px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 0 2px 2px rgba(21, 21, 21, 0.5);
    height: auto;
    text-align: left;
    font-size: 15px;
}
.show-points-dv span{
    font-weight: bold;
}
.show-points-dv p{
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.button-upload-csv-hover{
    background-color: green !important;
}
.button-upload-csv-hover:hover{
    background-color: rgb(11, 95, 11) !important;
}
.content-mnu-process-upload-csv{
    width: 50%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
}
.custom-modal-upload-csv{
    width: 50%;
    min-width: 50%;
}
.points-delete-upload-csv{
    height: 60px;
    width: 75%;
    margin: 0 auto;
    max-height: 60px;
    overflow: auto;
    color: black;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 10px;
    grid-template-areas: "a b c";
    margin-bottom: 20px;
    background-color: rgb(243, 243, 243);
    padding: 10px;
}
.points-delete-upload-csv-h2{
    font-size: 20px;
    font-weight: bold;
}
.points-delete-upload-csv p{
    font-weight: 100;
}
.showhidemap{
    cursor: pointer;
    font-size: 40px !important;
}
.button-ad-sector-map{
    background-color: #630971;
    color: white;
    position: relative;
    margin-top: 30px !important;
}