.container-profile{
    height: auto;
    width: 95%;
    margin: 0 auto;
}
.container-profile-grid{
    margin: 0 auto;
    display: grid;
    width: 100%;
    grid-template-columns: 20% 80%;
    grid-template-areas: 
    "a b";
    grid-gap:10px;
}
.menu-lateral{
    grid-area: a;
}
.dash-profile{
    grid-area: b;
}
.info-user{
    height: auto;
    padding-bottom: 50px;
}
.circle-init{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1c3848;
    height: 90px;
    width: 90px;
    border-radius: 50%;
}
.circle-init p{
    padding-top: 20%;
    font-size: 60px;
    font-weight: bold;
    color: white;
}
.name-info{
    padding-top: 10px;
    padding-bottom: 20px;
}
.name-info .name-completed{
    font-size: 30px;
}
.name-info .text-1{
    width: 82%;
    margin: 0 auto;
    font-size: 20px;
}
.secciones-info{
    margin: 0 auto;
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-template-areas: 
    "a b";
    grid-gap:20px;
}
.cart1{
    grid-area: a;
}
.cart2{
    grid-area: b;
}
.info-personal{
    border: 1px solid rgb(162, 162, 162);
    border-radius: 15px;
    padding: 15px;
}
.title-cart{
    text-align: left;
    font-size: 22px;
    font-weight: bold;
    color: #1c3848;
}
.desc-cart{
    font-size: 18px;
    text-align: justify;
    display: grid;
    grid-template-columns: 70% 25%;
    grid-template-areas: 
    "a b";
    grid-gap: 10px;

}
.desc-p-cart{grid-area: a; padding-right: 10px;}
.desc-img-cart{
    grid-area: b;
    margin-top: -20px;
    width: 100px;
}
.menu-lateral-item{
    display: grid;
    height: 60px;
    width: 100%;
    grid-template-columns: 20% 80%;
    grid-template-areas: 
    "a b";
    padding-top: 10px;
}
.menu-lateral-item:hover{
    cursor: pointer;
}
.menu-lateral-item .icono{
    grid-area: a;
}
.menu-lateral-item .icono svg{
    font-size: 40px;
    color: rgb(88, 88, 88);
}

.menu-lateral-item .item{
    grid-area: b;
    font-size: 20px;
    font-weight: 500;
    color: rgb(48, 48, 48);
    text-align: left;
    padding-top: 8px;
    padding-left: 5px;
    height: auto;
}
.menu-lat-title{
    height: 20px;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
}
.selec-item{
    background-color: #1c3848;
    border-radius: 15px;
    height: auto;
    padding-bottom: 10px;
}
.selec-item .icono svg{
    color: white;
}
.selec-item .item{
    color: white;
}

.cart1:hover{
    background-color: #e4e4e4;
    cursor: pointer;
}
.cart2:hover{
    background-color: #e4e4e4;
    cursor: pointer;
}

.configuration_user_dynamo{
    padding: 15px;
    border: 1px solid gray;
    border-radius: 15px;
    width: 102%;
    margin: 0 auto;
    margin-top: 20px;
}
.configuration_user_dynamo .desc-p-dyn{ 
    font-size: 18px;
    text-align: justify;
}
.info-conf-user{
    display: grid;
    grid-template-columns: 80% 20%;
    grid-template-areas: "a b";
    padding-bottom: 15px;
}
.dyn-1{
    grid-area: a;
}
.dyn-2{
    grid-area: b;
}
.dyn-2 img{
    width: 90%;
    margin-top: 20px;
}
.configuration-1{
    width: 100%;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: "a b";
    border-top: 1px solid rgb(169, 169, 169);
    padding-top: 20px;
}
.confp{
    grid-area: a;
    text-align: left;
    font-size: 20px;
}
.confpp{
    grid-area: b;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
}
.configuration-2{
    width: 100%;
    border-top: 1px solid rgb(169, 169, 169);
    padding-top: 20px;
    padding-bottom: 20px;
}
.grid-container-fav-conf {
    margin-left: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Crea tres columnas con igual ancho */
    gap: 10px; /* Espacio entre los elementos (opcional) */
  }
  
.grid-item-fav-conf {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
}
.configuration-3{
    width: 100%;
    border-top: 1px solid rgb(169, 169, 169);
    padding-top: 20px;
    display: grid;
    grid-template-columns: 80% 15%;
    grid-template-areas: "a b";
    grid-gap: 15px;
}
.button-save-config{
    background-color: #1c3848;
    padding: 15px;
    color: white;
    font-size: 20px;
    border-radius: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.conf3-1{
    text-align: left;
    grid-area: a;
}
.conf3-1 p{
    font-size: 18px;
    text-align: justify;
}
.conf3-2{
    grid-area: b;
    text-align: right;
    padding-top: 20px;
    width: 100%;
    margin-left: 20px;
}

.button-save-config:hover{
    cursor: pointer;
}









/* ///////////////////////////////INFORMACION PERSONAL//////////////////////// */
.info-descripcion{
    text-align: center;
    margin: 0 auto;
    padding-bottom: 20px;
}
.info-des-title{
    font-size: 25px;
    font-weight: 600;
    color: black;
    height: 30px;
}
.info-des-p{
    font-size: 20px;
}
.info-basic{
    border: 1px solid gray;
    width: 80%;
    margin: 0 auto;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 20px;
}
.info-basic-title{
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}
.info-basic-text{
    font-size: 18px;
    text-align: justify;
}
.formulario{
    margin: 0 auto;
    display: grid;
    width: 100%;
    grid-template-columns: 30% 70%;
    grid-template-areas: 
    "a b";
    border-bottom: 1px solid rgb(194, 194, 194);
    padding-top: 15px;
}
.formulario-close{
    border-bottom: none;
}
.formulario .text-form{
    grid-area: a;
    text-align: left;
    font-size: 18px;
}
.formulario span{
    font-weight: bold;
    grid-area: b;
    text-align: left;
    font-size: 18px;
}


.roles {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    height: 300px;
    overflow: scroll;
}
.roles p {
    width: 50%;
}
.role-item{
    text-align: left;
    font-weight: bold;
    font-size: 15px;
    padding-right: 10px;
}

/* ///////////////////////////////////seguridad/////////////////////////////// */

.info-des-title-priv{
    font-size: 35px;
    font-weight: bold;
    font-weight: bold;
}

.container-privacidad-text{
    margin: 0 auto;
    width: 85%;
    border: 1px solid gray;
    border-radius: 15px;
    padding: 20px;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-areas: "a b";

}
.cont-priv-t1{
    grid-area: a;
    font-size: 18px;
    text-align: justify;
}
.title-priv-text{
    font-weight: bold;
    font-size: 20px;
}
.cont-priv-t2{
    grid-area: b;
    padding: auto;
}
.cont-priv-t2 img{
    width: 70%;
}
.container-change-pass{
    border: 1px solid gray;
    border-radius: 15px;
    width: 85%;
    margin: 0 auto;
    margin-top: 20px;
    padding: 20px;
}

.change-pass{
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-areas: 
    "a b"
}

.pass-secc1{
    grid-area: a;
}

.change-pass .title-pass{
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}
.change-pass .text-pass{
    font-size: 18px;
    text-align: left;
}
.pass-secc2{
    grid-area: b;
    text-align: left;
}
.botton-pass{
    margin-top: 35px;
    font-size: 18px;
    font-weight: bold;
}
.botton-pass svg{
    font-size: 50px;
}
.botton-pass:hover{
    cursor: pointer;
}

.pass-change-method{
    grid-area: C;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas:  "a b";
    width: 100%;
    margin-top: 20px;
}
.input-1{
    grid-area: a;
    text-align: left;
}
.input-2{
    grid-area: b;
    text-align: left;
    padding-left: 10px;
}
.input-2 .check-bottom{
    padding-top: 60px;
}

.input-p{
    height: 50px;
    font-size: 20px;
    text-align: left;
    padding: 10px;
    color: black;
    width: 80% !important;
}
.input-p2{
    height: 50px;
    font-size: 20px;
    text-align: left;
    padding: 10px;
    color: black;
    width: 80% !important;
}

.checkbox-label {
 padding-top: 15px;
 display: flex;
 align-items: center;
 font-size: 18px;
 color: gray;
}

.checkbox-label input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: -5px;
}
.title-pass-change{
    font-size: 18px;
    text-align: left;
    font-weight: bold;
}


.version{
    float: right !important;
    padding-right: 3%;
}
.acciones-boton{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    grid-template-areas:  "a b";
    text-align: left;
}
.acciones-boton button{
    border-radius: 15px;
    height: 50px;
    width: 80%;
    font-size: 20px;
}
.expr-reg p{
    text-align: left;
    font-size: 15px;
    color: red;
    height: auto;
}
.loading{
    text-align: center;
    margin: 0 auto;
}
.gif-p{
    font-size: 20px;
    color: rgb(27, 62, 143);
    
}
.info-session-time{
    margin-top: 30px !important;
    width: 85%;
    margin: 0 auto;
    border: 1px solid gray;
    border-radius: 15px;
    display: grid;
    padding: 20px;
}
.info-s-1 .title-session{
    font-size: 20px;
    text-align: left;
    font-weight: bold;
}
.info-s-1 .p-session{
    text-align: justify;
    font-size: 18px;
}

.info-s-2, .info-s-3{
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-areas: "a b";
    margin: 0 auto;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 0px;
}
.info-s-2{
    border-bottom: 1px solid gray;
}

.info-s-2 .title-session, .info-s-3 .title-session{
    grid-area: a;
    font-size: 18px;
    text-align: left;
}
.info-s-2 .time-ss, .info-s-3 .time-ss{
    grid-area: b;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}


.expr-reg{grid-area: a;}
.button-change{
    grid-area: b;
}