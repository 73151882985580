.content-transf{
    width: 70%;
    margin: 0 auto;
    background-color: white;
    border: 1px solid gray;
    border-radius: 10px;
}
.title-trasnfer{
    padding-bottom: 20px;
}
.secc1{
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-areas: "a b";
    width: 90%;
    margin: 0 auto;
}
.secc1 .texttransfer{
    grid-area: a;
    text-align: justify;
}
.secc1 .buttontransf{
    grid-area: b;
    text-align: right;
}


.texttransfer h3{
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 22px;
}
.texttransfer p{
    margin-top: 0px;
    width: 90%;
    padding-top: 0px;
    text-align: justify !important;
}

.secc1 .buttontransf button{
    width: 200px;
    color: white;
    font-weight: bold;
    background-color: #1a374d;
    font-size: 20px;
    border-radius: 5px;
    padding-top: 5px;
    margin-top: 15px;
}
.button-disabled{
    background-color: gray !important;
}

.botones-trasnfer{
    margin-bottom: 20px;
}
.status-progress{
    display: flex;
    width: 90%;
    margin: 0 auto;
}
.status-progress P{
    font-size: 18px;
    padding-left: 10px;
}
.status-progress svg{
    cursor: pointer;
    color: black;
}
.span-svg{
    margin-left: 5px;
}
.span-svg svg{
    font-size: 35px;
}