.bandeja-container-mini{
    width: 98%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: "a b";
    border-bottom: 1px solid gray;
    padding-top: 5px;
    padding-bottom: 5px;
}
.circle-mini{
    width: 40px;
    height: 40px;
    background-color: #1a374d;
    grid-area: a;
    border-radius: 50%;
    margin-top: 10px;
}
.circle-mini p{
    font-size: 20px;
    color: white;
    font-weight: bold;
    padding-top: 5px;
    
}

.info-contact-list-mini{
    grid-area: b;
    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-areas: "a b";
}

.name-contact-mini{
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.name-contact-mini p{
    text-align: left;
    overflow-x: hidden;
    white-space: nowrap; 
    overflow: hidden;    
    text-overflow: ellipsis;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: bold;
}

.tiempo-contact-last-mss-mini p{
    font-size: 12px !important;
    text-align: right;
    margin-bottom: 5px;
    padding-bottom: 0px;
}
.info-last-mss-mini p{
    text-align: left;
    white-space: nowrap; 
    overflow: hidden;    
    text-overflow: ellipsis;

}
.chat-history-user{
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: relative;
}

.my-conversation{
    height: 50px;
    background-color: #edf3f9;
    display: grid;
    grid-template-columns: 5% 80% 10%;
    grid-template-areas: "a b c";
    width: 100%;
    margin: 0 auto;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.my-conversation .back{
    grid-area: a;
}
.my-conversation .back svg{
    font-size: black;
    font-size: 30px;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 3px;
    cursor: pointer;
}
.close-chat-active{
    padding-top: 5px;
    text-align: right !important;
}

.my-conversation .chat-actual-mini{
    grid-area: b;
    display: grid;
    padding-top: 10px;
}
.my-conversation .chat-actual-mini p{
    text-align: center;
    overflow-x: hidden;
    white-space: nowrap; 
    overflow: hidden;    
    text-overflow: ellipsis;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: bold;
}


.bandeja-mini-chat .header-chat{
    height: 50px;
    background-color: #ecf2f7;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.bandeja-mini-chat .body-chat{
    height: 300px;
    width: 100%;
}

.conversation-mini{
    padding-top: 10px;
    height: auto;
    overflow-y: scroll;
    position: relative;
    width: 100%;
    height: 300px;
}
.input-chat-mini{
    height: 50px;
}
.body-chat{
    height: auto;
    overflow-y: scroll;
}

.chat-selected-mini:hover{
    cursor: pointer;
    background-color: #dddddd;
}


/* inputs opciones */
.inputs-options{
    display: grid;
    grid-template-columns: 10% 80% 10%;
    padding-top: 10px;
}
.input-component textarea{
    height: 35px;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    max-height: 30px;
    min-height: 30px;
    overflow: auto;
    resize: none;
}
.icnos{
    padding-top: 2px;
}
.icnos svg{
    font-size: 30px;
}
.icnos svg:hover{
    cursor: pointer;
    color: #1a374d;
}
.sent-chat-voice svg:hover{
    cursor: pointer;
    color: #1a374d;
}

.record-voice{
    display: flex;
    text-align: center;
}
.record-voice img{
    height: 30px;
    width: 100%;
    filter: brightness(0) !important;
}

.multimedia-select-chat{
    height: 60px;
    margin-top: -60px !important;
    z-index: 200 !important;
    position: relative;
    width: 40px;
    text-align: center;
}
.iconos-add-functions{
    width: 100%;
}
.multimedia-select-chat svg{
    font-size: 30px;
    color: rgb(43, 43, 43);
}
.multimedia-select-chat svg:hover{
    color: #1a374d;
    cursor: pointer;
}

.upload-img-mini{
    width: 100%;
    height: 100px;
    margin-top: -100px !important;
    position: relative;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.23);
    text-align: center;
}
.upload-img-mini img{
    height: 100%;
    text-align: center;
}
.upload-img-mini svg{
    color: red;
    position: absolute;
    top: 0;
    right: 0;
    /* background-color: white; */
    border-radius: 50%;
}
.upload-img-mini svg:hover{
    cursor: pointer;
}



.header-chat{
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-areas: "a b";
    width: 100%;
    margin: 0 auto;
    padding-top: 10px !important;
}
.title-chat-smit-mini{
    text-align: left;
    font-size: 20px;
    color: black;
    grid-area: a;
    padding-left: 15px;
}

.header-chat .actions-menu-chat-mini{
    display: flex;
    text-align: right;
    grid-area: b;
}
.header-chat .actions-menu-chat-mini svg{
    font-size: 30px;
    margin-left: 10px;
}
.header-chat .actions-menu-chat-mini svg:hover{
    cursor: pointer;
}

.upload-audio-mp3-mini{
    height: 30px;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.upload-audio-mp3-mini audio{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 30px;
}
.upload-audio-mp3-mini svg{
    position: absolute;
    top: 0;
    color: red;
    right: 0;
    font-size: 15px;
}
.upload-audio-mp3-mini svg:hover{
    cursor: pointer;
}

.upload-file-mini{
    width: 100%;
    height: 50px;
    margin-top: -50px;
    position: relative;
    z-index: 200 !important;
    background-color: rgba(0, 0, 0, 0.353);
    padding: 10px;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    grid-template-areas: "a b c";
}
.upload-file-mini img{
    width: 30px !important;
    height: 30px !important;
}
.upload-file-mini svg{
    color: red;
}
.upload-file-mini svg:hover{
    cursor: pointer;
}
.upload-file-mini p{
    color: black;
    font-weight: bold;
    text-align: left;
    white-space: nowrap; 
    overflow: hidden;    
    text-overflow: ellipsis;
    height: 30px;
    padding-top: 5px;
}

.icono-copypaste{
    text-align: right;
    width: 100%;
}
.copy-query svg{
    float: right;
    margin: 5px;
    color: gray;
}
.copy-query svg:hover{
    cursor: pointer;
    color: black;
}
.copy-query p{
    text-align: justify;
}


