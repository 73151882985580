.container-config{
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.title-container{
    height: 60px;
    background-color: rgb(39, 39, 119);
}
.title-new-chat-mss .conf-title{
     margin-left: -20px;
     padding-top: 10px;
}
.opcionlist{
    width: 100%;
    padding-top: 10px;
}
.opcionlist .fondo{
    padding-top: 15px;
    padding-bottom: 20px;
    display: flex;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
}
.opcionlist .fondo p{
    padding-left: 15px;
    font-size: 18px;
}

.opcionlist .fondo:hover{
    cursor: pointer;
    background-color: rgb(209, 234, 255);
}
.upload_img{
    width: 100%;
    padding: 10px;
}

.boton-fondos{
    display: flex;
    margin: 0 auto;
}
.upload-img-event{
    background-color: rgb(132, 221, 253);
    border-radius: 15px;
    width: 100%;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
}  
.upload-img-event:hover{
    cursor: pointer;
}

.upload-img-event p{
    font-size: 18px;
    text-align: center;
    color: black;
    padding-top: 10px;
}

.boton-fondos .bbtt1{
    width: 50%;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    color: rgb(5, 5, 62);
}
.boton-fondos .bbtt2{
    font-weight: bold;
    text-align: center;
    width: 50%;
    font-size: 18px;
    color: rgb(5, 5, 62);
}

.boton-fondos p{
    font-size: 18px;
    text-align: center;
}

.boton-fondos .bbtt1 p:hover{
    color: rgb(90, 200, 241);
    cursor: pointer;
}
.boton-fondos .bbtt2 p:hover{
    color: red;
    cursor: pointer;
}
.load-img-upload p{
    text-align: center;
    color: rgb(18, 18, 108);
}

.imgseleccc{
    height: auto;
    max-height: 300px;
    width: 100%;
}
