.version-info-container{
    width: 105%;
    margin-top: -24px;
    margin-left: -20px;
    height: auto;
    background-color: #0b1b3c;
    padding-bottom: 50px;
}
#version101::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../imgComponents/patch/slide.png");
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(10px);
    z-index: 5; 
    background-position-y: 20%;
    height: 50vh;
    margin-left: -20px;
    margin-top: -20px;
    width: 105%;
}
.version-info-content img{
    width: 45%;
    position: relative;
    z-index: 10;
    margin-top: 70px;
}

.version-info-body{
    margin-top: 50px;
    z-index: 50;
    position: relative;
}
.version-info-sections{
    width: 90%;
    margin: 0 auto;
}
.version-info-sections .titles{
    width: 100%;
    text-align: center;
}
.version-info-sections .titles h2{
    color: white;
    font-size: 40px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.version-info-sections .titles p{
    color: white;
    font-size: 24px;
    font-weight: 100;
}
.version-info-sections .titles .line-gold{
    height: 1px;
    background-color: gold;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;
}
.version-info-sections .titles span{
    color: #e3bc6c;
}


.version-info-notes{
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
}

.version-info-notes .section1{
    margin-bottom: 30px;
}

.version-info-notes p{
    color: rgb(238, 238, 238);
    text-align: justify;
    font-size: 18px;
}

.version-info-notes .section2,
.version-info-notes .section3,
.version-info-notes .section4,
.version-info-notes .section5,
.version-info-notes .section6,
.version-info-notes .download
{
    border: 1px solid rgba(255, 255, 255, 0.466);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 40px;
}

.title-tutorial{
    color: white;
    text-align: left;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
}
.version-info-notes .section2 h2{
    color: white;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
}
.version-info-notes .section2 img{
    width: 40%;
}

.version-info-notes .section3 img{
    width: 30%;
}
.version-info-notes .section4 img{
    width: 40%;
}

.version-info-notes .section5{
    background-color: rgb(7, 7, 57);
}

.version-info-notes .section5 h2{
    color: #e3bc6c;
    font-size: 30px;
    font-weight: bold;
}
.version-info-notes .section5 ul{
    text-align: left;
    padding-left: 50px;
}
.version-info-notes .section5 ul li{
    color: white;
    font-size: 18px;
    list-style-type: disc;
    margin-bottom: 5px;
}

.version-info-notes .download{
    font-size: 18px;
    color: white;
    text-align: center;
}

.version-info-notes .download p{
    width: 100%;
    text-align: justify;
}

.version-info-notes .download img{
    width: 30%;
    cursor: pointer;    
}
.button-accions{
    width: 70%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 2%;
    padding-top: 15px;
    padding-bottom: 15px;
}
.button-accions .button-pdf-manual{
    background-color: #bb2748;
    display: flex;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    height: 60px;
    width: 90%;
    cursor: pointer;
}

.button-accions .button-view-manual{
    background-color: #1e3462;
    display: flex;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    height: 60px;
    width: 90%;
    cursor: pointer;
}

.button-accions .button-view-manual img{
    width: 50px;
}

.button-accions .button-pdf-manual img{
    width: 50px;
}

.button-accions .button-view-manual p,
.button-accions .button-pdf-manual p{
    margin-left: 10px;
    text-align: center;
    font-size: 24px;
}

.image-large{
    width: 70%;
    min-width: 70%;
}
.list-p{
    padding-left: 20px;
}
.title-p-notes{
    font-size: 20px !important;
    font-weight: bold;
}
.section4 ul{
    text-align: left;
    font-size: 18px;
    color: white;
    padding-left: 15px;
}
.section4 li {
    color: white;
    list-style-type: disc;
    margin-bottom: 15px;
}
.campos-notes-cond .title-list-inputs{
    font-weight: bold;
    font-size: 20px;
    font-style: italic;
}



.confirm-notice{
    border: 1px solid rgba(255, 255, 255, 0.466);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 40px;
}
.h2confirmnotice{
    text-align: left;
    color: white;
    font-size: 25px;
    font-weight: bold;
}
.button-confirm-term-patch{
    width: 80%;
    margin: 0 auto;
    text-align: center;
}
.button-confirm-term-patch button{
    background-color: #0f5699;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    height: auto;
}
.grid-confirm-inputs{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-areas: "a b c";
    grid-gap: 10px;
    width: 90%;
    margin: 0 auto;
}
.input-code-confirm-patch{
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid white;
    color: white;
    background-color: transparent;
    font-size: 20px;
    padding: auto;
    font-weight: bold;
    text-align: center;
}

.button-confirm-term-patch .load{
    width: 80%;
    margin: 0 auto;
    text-align: center;
}
.button-confirm-term-patch .load img{
    width: 20%;
}

@media screen and (max-width:1000px) {
    .button-accions{
        width: 90%;
    }
    .button-accions .button-view-manual p,
    .button-accions .button-pdf-manual p{
        font-size: 20px;
    }
    .version-info-sections .titles h2{
        font-size: 35px;
        font-weight: bold;
    }
}

@media screen and (max-width: 480px) {
    .version-info-notes .download .button-accions{
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas: "a""b";
        grid-gap: 0px;
    }
    .button-accions .button-pdf-manual,
    .button-accions .button-view-manual{
        width: 100%;
        margin-bottom: 10px;
    }
    .button-accions .button-pdf-manual p,
    .button-accions .button-view-manual p{
        font-size: 16px;
    }
    .version-info-notes{
        width: 90%;
    }
    .version-info-content img{
        width: 80%;
        margin-top: 70px;
    }
    .section6 img{
        width: 95%;
    }
    .version-info-sections .titles h2{
        font-size: 30px;
        font-weight: bold;
    }
    .grid-confirm-inputs{
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas: "a""b""c";
        grid-gap: 10px;
        
    }
    .formulario iframe{
        width: auto;
    }
    
}

.formulario{
    width: 100%;
    border: 1px solid gray;
    border-radius: 10px;
    text-align: center;
    margin: 0 auto;
    overflow-x: auto;
}
.formulario iframe{
    text-align: center;
    margin: 0 auto;
    margin-left: 20%;    
}


.formulario .xpWfed{
    display: none;
}