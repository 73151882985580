.mapboxgl-popup {
    max-width: 200px !important;
    min-width: 200px !important;
}
.mapboxgl-popup button{
    height: 15px !important;
    width: 20px !important;
    padding: 0px;
    margin: 0px;
    color: black;
    font-size: 20px;
    overflow: hidden;
}

.mapboxgl-popup div{
    color: rgb(0, 0, 0) !important;
    padding-top: 15px;
}
.marker-label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /* background-color: white; */
    padding: 2px 6px;
    margin-top: -10px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
  }

.control-pers-mapb{
    background-color: white;
    width: 35px;
    height: 118px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px rgba(21, 21, 21, 0.5);
    border-radius: 1px;
    z-index: 100;
}
.control-pers-mapb .controlss-pers{
    width: 95%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 100%; 
    grid-template-areas: 
    "a"
    "b"
    "c";
    grid-gap: 10px;
}
.controlss-pers span{
    border-bottom: 2px solid rgb(200, 200, 200);
    padding-bottom: 3px;

}

.button-icon-mapb-pers{
    font-size: 30px !important;
    color: gray;
    cursor: pointer;
}
.button-icon-mapb-pers2, .button-icon-mapb-pers3{
    font-size: 25px !important;
    color: gray;
    cursor: pointer;
}

.conf-map{
    width: 500px;
    height: 50px;
    background-color: white;
    position: absolute;
    margin-left: 70px;
    margin-top: 0px;
    top: 0;
    left: 0;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px rgba(21, 21, 21, 0.5);
    border-radius: 1px;
}
.conf-map .forms{
    display: grid;
    width: 95%;
    margin: 0 auto;
    grid-template-columns: 28% 28% 35% 10%;
    grid-template-areas: "a b c d";
}

.conf-map .forms select{
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 15px;
}
.save-seccion{
    text-align: center;
    padding-top: 10px;
    padding-left: 10px;
}
.save-seccion svg{
    font-size: 30px;
    cursor: pointer;
}

.cont-map-box{
    position: relative;
}

