

.body-bot-container{
    height: 70%;
}
.input-bot{
    height: 18%;
    width: 100%;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    grid-template-areas: "a b c";
}
.input-bot input{
    height: 40px;
    max-width: 100%;
    margin: 0 auto;
    color: black;
}
.input-bot-plac{
    grid-area: b;
}
.span-input-icon{
    grid-area: c;
}
.span-input-icon svg{
    font-size: 40px;
    text-align: center;
    cursor: pointer;
    padding-top: 5px;
}

.baninbbot{
    width: 100%;
    margin: 0 auto;
    height: 100%;
    overflow-y: auto;
    padding-top: 10px;
    background-color: rgb(245, 245, 245);
}
.mss-bot{
    display: grid;
    grid-template-columns: 80% 20%;
    grid-template-areas: "a b";
    width: 100%;
    margin-bottom: 15px;
}
.mss-user{
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: "a b";
    width: 100%;
    margin-bottom: 15px;
}
.msj-bot{
    grid-area: a;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid gray;
}
.msj-bot p, .msj-user p{
    text-align: justify;
    font-size: 15px;
}

.msj-user{
    grid-area: b;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px solid gray;
}
.load-mss-bot{
    width: 90%;
    text-align: center;
}
.load-mss-bot img{
    width: 70%;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.load-mss-bot p{
    padding-top: 0px;
    margin-top: 0px;
    font-size: 20px;
    color: #0c5cac;
}   

.navbar-bot{
    height: 12%;
    background-color:#ecf4f4;
    color: white;
    margin: 0 auto;
}
.nav-bot-bot{
    width: 90%;
    margin: 0 auto;
    display: flex;
}
.navbar-bot .nav-bot-bot p{
    text-align: center;
    font-size: 25px;
    width: 80%;
    margin: 0 auto;
    padding-top: 5px;
}
.navbar-bot .nav-bot-bot svg{
    color: black;
    font-size: 30px;
    margin-top: 7px;
    cursor: pointer;
}  

.bot-avatar-img{
    width: 70%;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    overflow: hidden;
}
.bot-avatar-img img{
    border-radius: 100%;
    width: 60%;
    background-color: #ecf4f4;
}

.cont-bot-opc{
    width: 95% !important;
    margin: 0 auto;
    text-align: left;
    padding: 0px;
}
.cont-bot-opc .select-opc-bot, .cont-bot-opc .select-opc-bot2{
    width: 95%;
}
.cont-bot-opc .select-opc-bot select, .cont-bot-opc .select-opc-bot2 select{
    width: 80% !important; 
}

.cont-bot-opc .select-opc-bot{
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.buton-action-bot{
    text-align: center;
    padding-top: 10px;
}
.select-opc-bot{
    margin-top: 10px;
    margin-bottom: 20px;
}
.buton-action-bot button{
    width: 60%;
    font-size: 20px;
    border-radius: 10px;
    text-align: center;
}
.loading-msj-receive{
    background-color: white;
    position: absolute;
    bottom: 0;
    padding: 0px;
    width: 99%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
}
.loading-msj-receive img{
    width: 50%;
    margin-bottom: 0px;
    padding-bottom: 0px;

}
.loading-msj-receive p{
    font-size: 22px;
    font-weight: bold;
    color: #0c5cac;
    padding-top: 0px;
    margin-top: -30px;
}

.msj-bot a{
    color: black !important;
    font-weight: bold;
    cursor: pointer;
}

.bottom-css-msj{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
}

.modalpdf{
    width: 80%;
    height: 90VH;
    max-height: 90vh;
    min-width: 80%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
}
.manual-container-pdf{
    height: 80vh;
    overflow: hidden;
}

.manual-container-pdf iframe{
    width: 100%;
    min-height: 100% !important;
    max-height: 100%;
    height: 100%;
}