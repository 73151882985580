
.content-form-applicants{
    width: 90%;
    margin: 0 auto;
}

.grid1-form-applicants{
    width: 100%;
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-gap: 2%;
    grid-template-areas: "a b b b c c";
}
.grid2-form-applicants{
    width: 100%;
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-gap: 2%;
    grid-template-areas: "a a b b b b";
}
.grid3-form-applicants{
    width: 100%;
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-gap: 2%;
    grid-template-areas: "a a a a a a";
}
.grid4-form-applicants{
    width: 100%;
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-gap: 2%;
    grid-template-areas: "a b b b c c";    
}
.grid5-form-applicants{
    width: 100%;
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-gap: 2%;
    grid-template-areas: "a a b b c c";
}
.grid0-form-applicants{
    width: 100%;
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-gap: 2%;
    grid-template-areas: "a a a a a a";
}

.item0-form-applicants{grid-area: a;}
.item1-form-applicants{grid-area: a;}
.item2-form-applicants{grid-area: b;}
.item3-form-applicants{grid-area: c;}

.item4-form-applicants{grid-area: a;}
.item5-form-applicants{grid-area: b;}

.item6-form-applicants{grid-area: a;}
.item7-form-applicants{grid-area: a;}

.item8-form-applicants{grid-area: a;}
.item9-form-applicants{grid-area: b;}
.item10-form-applicants{grid-area: c;}

.item11-form-applicants{grid-area: a;}
.item12-form-applicants{grid-area: b;}
.item13-form-applicants{grid-area: c;}

.item13-form-applicants div, .item3-form-applicants div{
    height: 35px !important;
    padding-top: 10px;
    margin-top: 1px;
    padding-bottom: 3px;
}
.item13-form-applicants label, .item3-form-applicants label{
    padding-top: 10px;
}