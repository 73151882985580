.content-evidence{
    width: 90%;
    margin: 0 auto;
    max-height: 80vh;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gallery-content {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-content img {
    height: 100%;
    object-fit: cover; 
    object-position: center;
}
.load-imgs-gif{
    width: 60%;
    margin: 0 auto;
}
.load-imgs-gif img{
    width: 100%;
    margin-top: 0px;
    padding-top: 0px;
}
.load-imgs-gif p{
    font-size: 30px;
    color: #19b1f8;
    font-weight: bold;
    text-align: center;
}


.prev-button, .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .prev-button {
    left: 10px;
  }
  
  .next-button {
    right: 10px;
  }