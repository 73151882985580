* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.home,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.userName {
  float: right;
  margin-right: 10px;
  margin-top: -50px;
  font-size: 18px;
  color: white;
}

.signout {
  float: right;
  margin-right: 10px;
}

.homeGrid {
  margin-top: 50px;
  display: grid;

  column-gap: 100px;

  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  padding-top: 20px;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #d32f2f !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #d32f2f !important;
}

.checkbox-ts {
  margin-top: -10px !important;
}


.css-1uvydh2{
  height: 0.3em !important;

}

.css-1x5jdmq{
  padding: 6.5px 14px !important; 
}

.css-o3kwm9{
  height: 38px !important;
}


/* Scrollbar for the table */


::-webkit-scrollbar {
  height: 8px; /* Horizontal scrollbar height */
  width: 8px; /* Vertical scrollbar width */
}

::-webkit-scrollbar-thumb {
  background-color: #b3b3b3 ; /* Scrollbar thumb color */
  border-radius: 5px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffa500; /* Thumb color on hover */
}

::-webkit-scrollbar-track {
  background-color: #ffffff; /* Scrollbar track color */
  border-radius: 5px;
}

::-webkit-scrollbar-track:hover {
  background-color: #45677e; /* Slightly brighter track on hover */
}

::-webkit-scrollbar-track-piece {
  height: 80%; /* Limit the track piece length */
}