.register-new-cabinets{
    width: 90%;
    margin: 0 auto;
    text-align: center;
    min-height: 70vh;
}
.process-loadbar-lote-register{
    min-height: 70vh;
}
.items-frezz-file{
    width: 95%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2% 15% 15% 15% 15% 15% 15%;
    grid-template-areas: "a b c d e f g";
    grid-gap: 10px;
}
.items-frezz-file p{
    font-size: 18px;
}
.model_p_error{
    color: red;
    font-size: bold;
}
.p-headrer-column{
    font-weight: bold;
}
.process-content-bar-csv
{
    width: 90%;
    margin: 0 auto;
}
.process-loadbar-lote-register{
    height: 300px;
}
.load-progress {
    width: 100%;
    height: 20px;
    background-color: #d2d1d1;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 20px;
}
.progress-bar {
    height: 100%;
    background-color: #007bff;
    transition: width 0.5s ease-in-out; /* Animación de llenado */
}
.content-upload-file-csv-frezzer{
    padding-bottom: 30px;
}
.progress-finaly-new_table_upload_csv{
    margin: 0 auto;
    width: 90%;
}
.rnc-info{
    width: 95%;
    margin: 0 auto;
    text-align: center;
}
.rnc-grid-button{
    width: 70%;
    display: grid;
    grid-template-columns: 45% 45%;
    grid-template-areas: "a b";
    grid-gap: 10%;
    margin: 0 auto;
}
.finish_process_uploadfile_cabinets{
    margin-top: 30px;
    text-align: right;
}
.finish_process_uploadfile_cabinets button{
    width: 200px;
    font-size: 18px;
    border-radius: 5px;
}


.file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .file-upload-container h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .drop-area {
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 2px dashed #ccc;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    cursor: pointer;
    margin-bottom: 5px;
    transition: border-color 0.3s, background-color 0.3s;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .drop-area.highlight {
    border-color: #007bff;
    background-color: #eaf4ff;
  }
  
  .icon-container_down {
    margin-bottom: 10px;
    width: 38px;
    height: 38px;
    color: #007bff;
  }
  
  .icon-upload {
    width: 38px;
    height: 38px;
    color: #007bff;
  }
  
  .file-upload-container p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #555;
    font-size: 1.1rem;
    font-weight: bold;
  }
  
  .description-text {
    margin: 10px 0;
    font-size: 0.85rem;
    color: #777;
    text-align: center;
  }
  
  .download-template-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    margin-top: 20px;
    
  }
  
  .download-template-button:hover {
    background-color: #0056b3;
  }
  
  .icon-download {
    width: 20px;
    height: 20px;
  }
  
  
  .info-box {
    display: flex;
    align-items: center;
    justify-content: center; /* Centra los ítems en el eje horizontal */
    gap: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px 15px;
    margin-top: 15px;
    width: 100%; /* Asegúrate de que ocupa todo el ancho */
    max-width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .info-icon {
    width: 24px;
    height: 24px;
    color: #007bff;
  }
  
  .info-box p {
    font-size: 1.1rem;
    font-weight: bold;
    color: #555;
    margin: 0;
    
  }
  
  
  .download-template-button {
    display: flex;
    align-items: center;
    justify-content: center; /* Asegura que el contenido esté centrado horizontalmente */
    gap: 8px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
.content-file-upload-cabinets{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
}   
