.control-type{
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    background-color: white;
    margin-left: 60px;
    margin-top: 10px;
    width: auto;
    height: 60px;
    z-index: 10;
    box-shadow: 0 0 2px 2px rgba(21, 21, 21, 0.5);
}
.content-deliveries-prevent-table{
    position: relative;
}
.mapas-show-vist{
    display: block;
}
.mapa-grid-show-vist{
    display: grid;
    grid-template-columns: 48% 48%;
    grid-template-areas: "a b";
    grid-gap: 10px;
}
.autocomp-inpt-mapbox{
    margin-top: 12px;
    padding-right: 10px;
}