.TableEmpleados .sticky-table-table {
  width: 100%;
}
.TableEmpleados {
  margin: 0 auto;
  width: 95% !important;
  margin-top: -50px;
}
.TableEmpleados .titletable {
  margin-top: -30px;
  margin-bottom: -50px;
}
.TableEmpleados td:nth-child(1) {
  text-align: 10px;
  min-width: 100px;
}
.TableEmpleados td:nth-child(2) {
  text-align: center;
}
.TableEmpleados td:nth-child(3),
.TableEmpleados td:nth-child(4) {
  text-align: center;
}
.TableEmpleados td:nth-child(5) {
  text-align: center;
}
.TableEmpleados td:nth-child(6),
.TableEmpleados td:nth-child(7) {
  text-align: center;
}

.formformat input {
  /* height: 50px; */
  font-size: 18px;
  border: 2px solid rgba(0, 0, 0, 0.286);
}
.formformat label {
  font-size: 19px;
  background-color: white;
  color: black;
}
.formformat select {
  height: 20px;
  font-size: 18px;
  border: 2px solid rgba(0, 0, 0, 0.286);
}
.formformat .inputpos {
  height: 70px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 10px;
}

.gridemployees1 {
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 16% 15% 15% 15% 15% 16%;
  grid-template-areas: "a a b b c c";
  grid-gap: 10px;
}
.gridemployees2 {
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 20% 10% 10% 26% 10% 16%;
  grid-template-areas: "a b b c d d";
  grid-gap: 10px;
}
.gridemployees3 {
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 16% 15% 15% 15% 15% 16%;
  grid-template-areas: "a a b b c c";
  grid-gap: 10px;
}
.gridemployees4 {
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 16% 15% 15% 15% 15% 16%;
  grid-template-areas: "a a b b c c";
  grid-gap: 10px;
}
.gridemployees5 {
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 16% 15% 15% 15% 15% 16%;
  grid-template-areas: "a a b b c c";
  grid-gap: 10px;
}
.gridemployees6 {
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 16% 15% 15% 15% 15% 16%;
  grid-template-areas: "a a a b b b";
  grid-gap: 10px;
}
.gridemployees7 {
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 95%;
  grid-template-areas: "a a a b b b";
  grid-gap: 10px;
}
/* datos de direccion */
.gridemployees8 {
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 16% 15% 15% 15% 15% 16%;
  grid-template-areas: "a a a a b c";
  grid-gap: 10px;
}

.gridemployeesdriver {
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 24% 24% 23% 23%;
  grid-template-areas: "a b c d";
  grid-gap: 10px;
}
.gridemployees9 {
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 16% 15% 15% 15% 15% 16%;
  grid-template-areas: "a a b b c c";
  grid-gap: 10px;
}
.gridemployees10 {
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 16% 15% 15% 15% 15% 16%;
  grid-template-areas: "a a b b c c";
  grid-gap: 10px;
}
.gridemployees11 {
  margin: 0 auto;
  display: grid;
  width: 90%;
  grid-template-columns: 16% 15% 15% 15% 15% 16%;
  grid-template-areas: "a a a a b c";
  grid-gap: 10px;
}
.employee1 {
  grid-area: a;
}
.employee2 {
  grid-area: b;
}
.employee3 {
  grid-area: c;
}

.employee4 {
  grid-area: a;
  padding-top: 12px;
  margin-left: 10px;
  overflow: visible;
}
.employee4 label {
  position: absolute;
  font-size: 14px;
  background-color: white;
  margin-left: 10px;
  margin-top: -10px;
  width: 15%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.employee5 {
  grid-area: b;
}
.employee6 {
  grid-area: c;
}
.employee7 {
  grid-area: d;
}

.employee8 {
  grid-area: a;
  padding-top: 12px;
  margin-left: 10px;
}
.employee8 label {
  position: absolute;
  font-size: 14px;
  background-color: white;
  margin-left: 10px;
  margin-top: -10px;
  width: 15%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.employeedriver01 {
  grid-area: a;
}
.employeedriver02 {
  grid-area: b;
}
.employeedriver03 {
  grid-area: c;
}
.employeedriver04 {
  grid-area: d;
}

.employee9 {
  grid-area: b;
}
.employee10 {
  grid-area: c;
}

.employee11 {
  grid-area: a;
}
.employee12 {
  grid-area: b;
}
.employee13 {
  grid-area: c;
}

.employee14 {
  grid-area: a;
}
.employee15 {
  grid-area: b;
}
.employee16 {
  grid-area: c;
}

.employee17 {
  grid-area: a;
}
.employee18 {
  grid-area: b;
}

.employee19 {
  grid-area: a;
}
.employee20 {
  grid-area: b;
}

.employee21 {
  grid-area: a;
}
.employee22 {
  grid-area: b;
}
.employee23 {
  grid-area: c;
}

.employee24 {
  grid-area: a;
}
.employee25 {
  grid-area: b;
}
.employee26 {
  grid-area: c;
}

.employee27 {
  grid-area: a;
}
.employee28 {
  grid-area: b;
}
.employee29 {
  grid-area: c;
}

.employee30 {
  grid-area: a;
}
.employee31 {
  grid-area: b;
}
.employee32 {
  grid-area: c;
}

@media only screen and (max-width: 720px) {
  .gridemployees1 {
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 16% 15% 15% 15% 15% 16%;
    grid-template-areas:
      "a a a a a a"
      "b b b c c c";
    grid-gap: 10px;
  }
  .gridemployees2 {
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 20% 10% 10% 26% 10% 16%;
    grid-template-areas: "a a a b b b" "c c c d d d";
    grid-gap: 10px;
  }
  .gridemployees3 {
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 16% 15% 15% 15% 15% 16%;
    grid-template-areas: "a a a a a a" "b b b c c c";
    grid-gap: 10px;
  }
  .gridemployees4 {
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 16% 15% 15% 15% 15% 16%;
    grid-template-areas: "a a a a a a" "b b b c c c";
    grid-gap: 10px;
  }
  .gridemployees5 {
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 16% 15% 15% 15% 15% 16%;
    grid-template-areas: "a a a a a a" "b b b c c c";
    grid-gap: 10px;
  }
  .gridemployees6 {
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 16% 15% 15% 15% 15% 16%;
    grid-template-areas: "a a a a a a" "b b b b b b";
    grid-gap: 10px;
  }
  .gridemployees7 {
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 95%;
    grid-template-areas: "a a a a a a" "b b b b b b";
    grid-gap: 10px;
  }

  .gridemployees8 {
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 16% 15% 15% 15% 15% 16%;
    grid-template-areas: "a a a a a a" "b b b c c c";
    grid-gap: 10px;
  }

  .gridemployeesdriver {
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 24% 24% 23% 23%;
    grid-template-areas: "a a b b" "c c d d";
    grid-gap: 10px;
  }
  .gridemployees9 {
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 16% 15% 15% 15% 15% 16%;
    grid-template-areas: "a a a a a a" "b b b c c c";
    grid-gap: 10px;
  }
  .gridemployees10 {
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 16% 15% 15% 15% 15% 16%;
    grid-template-areas: "a a a a a a" "b b b c c c";
    grid-gap: 10px;
  }
  .gridemployees11 {
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 16% 15% 15% 15% 15% 16%;
    grid-template-areas: "a a a a a a" "b b b c c c";
    grid-gap: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .gridemployees1 {
    grid-template-areas: "a" "b" "c";
    grid-template-columns: 95%;
    grid-gap: 0px;
  }

  .gridemployees2 {
    grid-template-areas: "a" "b" "c" "d";
    grid-gap: 0px;
    grid-template-columns: 95%;
  }
  .gridemployees3 {
    grid-template-columns: 95%;
    grid-template-areas: "a" "b" "c";
    grid-gap: 0px;
  }
  .gridemployees4 {
    grid-template-columns: 95%;
    grid-template-areas: "a" "b" "c";
    grid-gap: 0px;
  }
  .gridemployees5 {
    grid-template-columns: 95%;
    grid-template-areas: "a" "b" "c";
    grid-gap: 0px;
  }
  .gridemployees6 {
    grid-template-columns: 95%;
    grid-template-areas: "a" "b";
    grid-gap: 0px;
  }
  .gridemployees7 {
    grid-template-columns: 95%;
    grid-template-areas: "a" "b";
    grid-gap: 0px;
  }
  .formformat .pestañas .navegacioncompany button {
    width: 100px;
    margin: 0 auto;
    text-align: center;
  }
  .employee8 label {
    width: 40%;
  }
  .employee4 label {
    width: 40%;
  }
  .employee8,
  .employee4 {
    margin-bottom: 10px;
    width: 100%;
  }

  .gridemployees8 {
    grid-template-columns: 95%;
    grid-template-areas: "a" "b" "c";
    grid-gap: 0px;
  }

  .gridemployeesdriver {
    grid-template-columns: 95%;
    grid-template-areas: "a" "b" "c" "d";
    grid-gap: 0px;
  }
  .gridemployees9 {
    grid-template-columns: 95%;
    grid-template-areas: "a" "b" "c";
    grid-gap: 0px;
  }
  .gridemployees10 {
    grid-template-columns: 95%;
    grid-template-areas: "a" "b" "c";
    grid-gap: 0px;
  }
  .gridemployees11 {
    grid-template-columns: 95%;
    grid-template-areas: "a" "b" "c";
    grid-gap: 0px;
  }
}
