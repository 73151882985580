.grid-detalles-bon{
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 10% 10% 10%;
    grid-template-areas: "a b c d e f g";
    width: 80%;
    grid-gap: 10px;
    position: relative;
    z-index: 100 !important;
}
.grid-detalles-bon .expen-1{
    grid-area: a;
}
.grid-detalles-bon .expen-2{
    grid-area: b;
}
.grid-detalles-bon .expen-3{
    grid-area: c;
}
.grid-detalles-bon .expen-4{
    grid-area: d;
}
.grid-detalles-bon .expen-5{
    grid-area: e;
}
.grid-detalles-bon .expen-8{
    grid-area: f;
}