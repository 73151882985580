.cursorPointer {
  cursor: pointer;
}

.modalSetBussnes {
  width: 80% !important;
}

.switchRegister {
  padding-left: 20px;
  padding-top: 10px;
}

.Modal-module_modal__FqeFw {
  overflow: unset !important;
}

body {
  --st-overlay-perspective: none !important;
}

.idAler {
  background-color: black !important;
}

.alertNotification {
  color: white !important;
  font-size: 25px !important;
}

.iconctnAlert {
  margin-top: 5px;
  width: 70%;
}

.ctnAler {
  grid-area: 1 / 1 / 3 / 2;
}
.ctnAler2 {
  grid-area: 1 / 2 / 2 / 4;
}
.ctnAler3 {
  grid-area: 2 / 2 / 3 / 4;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled,
.css-12ogz78-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: unset !important;
  -webkit-text-fill-color: black !important;
}

.css-1kw4qaw-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: white !important;
  box-shadow: none !important;
}

.containerBussnesP {
  display: grid;
  grid-template-areas: "expen1 expen2 expen3 expen4 expen5 expen8 expen7";
  gap: 10px;
  position: relative;
  width: 90%;
  z-index: 1;
  padding-left: 2.5%;
  margin: 10px;
}

.contenirdivH {
  width: 100%;
  margin-left: 3%;
}

/* .modalTItle{
  height: auto;
  overflow: scroll;
} */
.modalpuntosventa {
  max-height: 100vh;
  position: absolute;
  margin-top: 1%;
}

.p-actions {
  margin-bottom: 0px !important;
  font-weight: bold !important;
  color: black !important;
}

.icon_add_qr {
  cursor: pointer;
  width: 30px !important;
}

.miIconButton {
  margin-bottom: 5px;
  width: 30px;
  position: absolute;
}

.new_table_v2 table tbody td:nth-child(5) {
  text-align: center !important;
}

.new_table_v2 thead tr,
.new_table_v2 thead tr:before {
  /* background: #26496f !important; */
  background: #1a4879 !important;
}

.new_table_v2 tfoot tr {
  background: #f1f1f1 !important;
}
.new_table_v2 tfoot tr div {
  color: black !important;
}
.new_table_v2 table thead tr div {
  color: white !important;
}

.new_table_v2 thead tr button svg,
.new_table_v2 thead tr span svg,
.new_table_v2
  thead
  tr
  .css-118d58w-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon,
.new_table_v2
  thead
  tr
  .css-1j43wpm-MuiButtonBase-root-MuiTableSortLabel-root
  .MuiTableSortLabel-icon {
  color: white !important;
}

.new_table_v2 .css-jchqo4-MuiFormHelperText-root,
.new_table_v2 .css-1a8ei4g {
  display: none;
}

.new_table_v2 tbody tr:nth-child(even) {
  background-color: rgb(243, 243, 243) !important;
}

.new_table_v2 thead .css-lapokc {
  overflow: visible;
  /* white-space: pre-line !important; */
}
.new_table_v2,
.new_table_v2 tbody td,
.new_table_v2 thead .css-lapokc,
.new_table_v2 table thead tr div,
.new_table_v2 table thead tr div input {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.new_table_v2 table thead tr div,
.new_table_v2 table thead tr div input {
  font-size: 0.8rem !important;
}

/* .cashclosing_sale_v2 table tbody td:nth-child(5) {
  text-align: center !important;
} */

.cashclosing_sale_v2 thead tr,
.cashclosing_sale_v2 thead tr:before {
  /* background: #26496f !important; */
  background: #1a4879 !important;
}

.cashclosing_sale_v2 tfoot tr {
  background: #f1f1f1 !important;
}
.cashclosing_sale_v2 tfoot tr div {
  color: black !important;
}
.cashclosing_sale_v2 table thead tr div {
  color: white !important;
}

.cashclosing_sale_v2 thead tr button svg,
.cashclosing_sale_v2 thead tr span svg,
.cashclosing_sale_v2
  thead
  tr
  .css-118d58w-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon,
.cashclosing_sale_v2
  thead
  tr
  .css-1j43wpm-MuiButtonBase-root-MuiTableSortLabel-root
  .MuiTableSortLabel-icon {
  color: white !important;
}

.cashclosing_sale_v2 .css-jchqo4-MuiFormHelperText-root,
.cashclosing_sale_v2 .css-1a8ei4g {
  display: none;
}

.cashclosing_sale_v2 thead .css-lapokc {
  overflow: visible;
  white-space: "normal";
}

.new_table_v2 table tbody td {
  font-size: 0.74rem !important
}

le_v2 table thead th {
  font-size: 0.74rem !important;
}


.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-12yjm75-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 5.5px 14px !important;
}

.css-161rayn-MuiFormControl-root-MuiTextField-root{
  height: 35px !important;
}