.bandeja-container{
    width: 100%;
    display: grid;
    grid-template-columns: 18% 82%;
    grid-template-areas: 
    "a b";
    height: 100%;
}
.bandeja-container .circle{
    background-color: rgb(7, 7, 71);
    width: 90%;
    height: 90%;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}
.bandeja-container .circle p{
    color: white;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    font-size: 25px;
    padding-top: 10px;
}

.container-history{
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.info-contact-list{
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-areas: "a b";  
    height: 30px;
}


.info-contact-list .name-contact{
    grid-area: "a";
}
.info-contact-list .name-contact p{
    font-size: 17px !important;
    white-space: nowrap;
    overflow: hidden;   
    text-overflow: ellipsis; 
    width: 95%;

}
.tiempo-contact-last-mss{
    grid-area: "b";
    padding-right: 10px;
}
.tiempo-contact-last-mss p{
    text-align: end;
    color: rgb(51, 51, 51);
    font-size: 13px;
}
.info-last-mss{
    color: black;
    height: 40px;
    grid-template-columns: 90% 10%;
    grid-template-areas: "a b";
}
.info-last-mss p{
    color: rgb(72, 72, 72);
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;   
    text-overflow: ellipsis; 
}