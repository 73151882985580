.form-create-promotion{
  width: 95%;
  margin: 0 auto;
}
.form-create-promotion input{
  height: 40px;
}
.form-create-promotion select{
  height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.grid-form1{
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 60% 30%;
  grid-template-areas: "a b";
  grid-gap: 10px;
}
.form-prom-campo1{
  grid-area: a;
}
.form-prom-campo2{
  grid-area: b;
}
.grid-form2{
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 33% 33% 33%;
  grid-template-areas: "a b c";
  grid-gap: 10px;
}
.form-prom-campo3{
  grid-area: a;
}
.form-prom-campo4{
  grid-area: b;
}
.form-prom-campo5{
  grid-area: c;
}

.form-prom-campo5{
  font-size: 18px;
  display: flex;
  padding-left: 10px;
  padding-top: 20px;
}
.form-prom-campo5 input{
  height: 25px;
  width: 25px;
}
.form-prom-campo5 div{  
  width: 25px;
  margin-left: 10px;
}

.grid-form3{
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 45% 45%;
  grid-template-areas: "a b";
  grid-gap: 10px;
  overflow: hidden;
}
.form-prom-campo6{
  grid-area: a;
  margin-left: 3%;
}
.form-prom-campo7{
  grid-area: b;
}

@media screen and (max-width: 480px) {
  .grid-form1{
    grid-template-columns: 90%;
    grid-template-areas: "a""b";
    grid-gap: 0px;
  }
  .grid-form2{
    grid-template-columns: 90%;
    grid-template-areas: "a""b""c";
    grid-gap: 0px;
  }
  .grid-form3{
    grid-template-columns: 93.5%;
    grid-template-areas: "a""b";
    grid-gap: 0px;
  }
  .grid-form3 input{
    width: 100%;
  }
  .form-prom-campo6{
    margin-left: 4%;
  }
  .form-prom-campo7{
    margin-left: 4%;
  }
}