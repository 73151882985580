.TablePriceList thead tr th:nth-child(1),
.TablePriceList td:nth-child(1) {
  text-align: center !important;
  width: 100px !important;
}

.TablePriceList td:nth-child(2) {
  text-align: left;
}

.TablePriceListDetail thead tr th:nth-child(5),
.TablePriceListDetail td:nth-child(5),
.TablePriceListDetail thead tr th:nth-child(6),
.TablePriceListDetail td:nth-child(6) {
  text-align: center !important;
  width: 150px !important;
}

.TablePriceListDetail td:nth-child(7) {
  text-align: right !important;
  width: 150px !important;
}

.TablePriceListDetail thead tr th:nth-child(2),
.TablePriceListDetail td:nth-child(2) {
  text-align: center !important;
  width: 200px !important;
}

.TablePriceListDetail thead tr th:nth-child(3),
.TablePriceListDetail td:nth-child(3) {
  text-align: justify;
}

.TablePriceList_detail {
  width: 60% !important;
}

.product-name {
  display: inline-block;
  width: 50%;
  text-align: left;
  padding-right: 10px;
}

.product-price {
  display: inline-block;
  width: 50%;
  text-align: left;
  padding-left: 10px;
}
