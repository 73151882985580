.gridweek{
    display: grid;
    align-items: flex-end;
    width: 50%;
    grid-template-columns: 25% 25% 20%;
    grid-template-areas:
    "a b c";
    grid-gap:10px;
    z-index: 10;
    position: relative;
    padding-bottom: 25px;
}
.week1{
  grid-area: a;
  position: relative;
  margin-bottom: 12px;
}
.week1 label{
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -10px;
  margin-left: 10px;
  font-size: 15px;
  color: black;
  background-color: white;
}
.week1 input{
  height: 46px;
}

.week2{
    grid-area: b;
}
.week2 input{
  height: 47px;
  font-size: 19px;
  color: black;
  background-color: white;
}
.week2 label{
  color: black;
}
.week3{
    grid-area: c;
    padding-bottom: 8px;
}
.week3 button{
    height: 45px;
    width: 80%;
    color: white;
}

.semaforo{
  width: 25px;
  height: 25px;
}

.Tableweek{
  margin-top: -70px;
}


.TableSummaryAnual .sticky-table-table{
  width: 100%;
}
.TableSummaryAnual {
  margin: 0 auto;
  width: 95% !important;
}

.TableSummaryAnual td:nth-child(1){
  text-align: center;
}
.TableSummaryAnual td:nth-child(2){
  text-align: center;
}
.TableSummaryAnual th:nth-child(2){
  max-width: 100px !important;
}
.TableSummaryAnual th:nth-child(2) select{
  width: 100% !important;
  text-align: left;
}

.TableSummaryAnual td:nth-child(3){
  max-width: 200px;
  white-space: normal !important;
  word-wrap: break; 
  text-align: left;

}
.TableSummaryAnual td:nth-child(4){
  text-align: center;
}

.TableSummaryAnual td:nth-child(5),
.TableSummaryAnual td:nth-child(6),
.TableSummaryAnual td:nth-child(7),
.TableSummaryAnual td:nth-child(8),
.TableSummaryAnual td:nth-child(9),
.TableSummaryAnual td:nth-child(10),
.TableSummaryAnual td:nth-child(11),
.TableSummaryAnual td:nth-child(12),
.TableSummaryAnual td:nth-child(13)
.TableSummaryAnual td:nth-child(14),
.TableSummaryAnual td:nth-child(15),
.TableSummaryAnual td:nth-child(16),
.TableSummaryAnual td:nth-child(17){
  text-align: right;
}
.TableSummaryAnual td:nth-child(18){
  text-align: right;
}

.deletepos{
  background-color: red !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.semaforo-celda{
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.tools-sum-week{
  display: flex;
  position: relative;
  z-index: 100;
}
.sumweek1 input{
  height: 33px !important;
}

.sumweek2{
  padding: 0px !important;
  margin-right: 20px;
  height: 30px;
  /* padding-top: -20px !important; */
  margin-top: -12px !important;
}
.sumweek2 input{
  width: 150px !important;
  height: 20px !important;
  margin-right: -25px !important;
}

.sumweek3{
  height: 30px !important;
}
.sumweek3 button{
  margin-top: 0px;
  height: 34px !important;
}

@media screen and (max-width:480px) {
    .tools-sum-week{
      display: grid;
      width: 90%;
      grid-template-columns: 100%;
      grid-template-areas:
      "a""b""c";
      grid-gap: 20px;
      z-index: 10;
      position: relative;
      margin: 0 auto;
    }
    .tools-sum-week .sumweek2{
      width: 100%;
      text-align: left;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    .tools-sum-week .sumweek2 div{
      margin-left: 0 !important;
      padding-left: 0 !important;
      margin-right: 0 !important;
      padding-right: 0 !important;
    }
    .tools-sum-week .sumweek2 div input{
      width: 100%;
    }
    .tools-sum-week .sumweek3{
       position: relative;
    }

    .tools-sum-week .sumweek3 button{
      width: 100%; 
      font-size: 10px !important;
      margin-left: 0px !important;
    }
  
}