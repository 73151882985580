.dashboard-acctions{
    display: flex;
    position: relative;
    z-index: 100 !important;
}
.dashacc1 input{
    width: 150px !important;
    margin-right: 10px;
    height: 40px;
}
.dashacc2 input{
    width: 150px !important;
    height: 40px;
}
.dashacc3{
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 200px !important;
}
.dashacc3 select{
    width: 100% !important;
}