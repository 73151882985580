.form-job-opp{
    width: 95%;
    margin: 0 auto;
    position: relative;
}
.grid-job-form{
    width: 98%;
    display: grid;
    grid-template-columns: 60% 35%;
    grid-template-areas: "a b";
    grid-gap: 20px;
    margin: 0 auto;
}
.grid-job-form-2{
    width: 98%;
    display: grid;
    grid-template-columns: 60% 35%;
    grid-template-areas: "a b";
    grid-gap: 20px;
    margin: 0 auto;
}
.grid-job-form-3{
    width: 98%;
    display: grid;
    grid-template-columns: 20% 30% 45%;
    grid-template-areas: "c a b";
    margin: 0 auto;
    grid-gap: 10px;
}

.grid-job-form-4{
    width: 98%;
    display: grid;
    grid-template-columns: 20% 30% 45%;
    grid-template-areas: "a a a";
    margin: 0 auto;
    grid-gap: 10px;
}


.jobformite1{grid-area: a;}
.jobformite2{grid-area: b;}
.jobformite3{grid-area: a;}
.jobformite4{grid-area: b;}
.jobformite5{grid-area: a;}
.jobformite6{grid-area: b;}
.jobformite7{
    grid-area: c;
    text-align: center;
    padding-top: 10px;
}
.jobformite8{grid-area: a;}
.jobformite9{grid-area: a;}
.jobformite10{grid-area: a;}
.jobformite8 textarea{
    margin-top: -10px !important;
    margin-bottom: -10px !important;
}
.jobformite9 textarea{
    margin-top: -10px !important;
    margin-bottom: -10px !important;
}
.jobformite10 textarea{
    margin-top: -10px !important;
    margin-bottom: -10px !important;
}
.button-action-job-opp{
    text-align: right;
    position: relative;
    width: 98%;
    margin: 0 auto;
}

@media screen and (max-width:1024px) {
    .grid-job-form-3{
        grid-template-areas:
            "c a a"
            "b b b";
        margin: 0 auto;
    }
}
.mod-edition-form-edit{
    width: 90%;
    margin: 0 auto;
    text-align: right;
}
.mod-edition-form-edit label{
    font-size: 20px;
    font-weight: bold;
}

.text-field-disabled .MuiInputLabel-root.Mui-disabled {
    color: black !important; 
    -webkit-text-fill-color: #7c7c7c !important;
    background-color: transparent !important; 
}
.text-field-disabled .MuiInputBase-input.Mui-disabled {
    color: black !important;
    -webkit-text-fill-color: black !important; 
    background-color: transparent;
    
}
